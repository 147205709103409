import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'
import ApiService from '../services/common/api.service'
import moment from 'moment-timezone'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  document.querySelector('html').style.overflow = 'auto'
  let isAuthenticated = store.getters['auth/isLoggedIn']
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !isAuthenticated
  ) {
    if (to.matched.some((record) => record.meta.redirect)) {
      next({ name: 'Login', query: { redirect: to.path } })
    } else {
      next({ name: 'Login' })
    }
  } else if (isAuthenticated) {
    var accessToken = store.getters['auth/currentAccessToken']
    if (accessToken == null) {
      await store.dispatch('auth/getAccessToken')
    }
    ApiService.reInitAuthorization()
    if (to.name === 'Login' || to.name === 'Register') {
      // Go back to home if user is authenticated and want go to login/register
      next('/')
    } else {
      // await store.dispatch('auth/getCurrentUser')

      // Get user detail
      let user = store.getters['user/getUserInfo']
      if (user === null) {
        await store.dispatch('user/loadUserFromToken')
        user = store.getters['user/getUserInfo']
      }

      if (user.company) {
        // Check if current package is expired
        let currentDate = moment()
        let endSubscriptionDate = moment(user.company.endSubscriptionAt)

        if (currentDate.isAfter(endSubscriptionDate)) {
          // all user can access package expired page
          if (to.matched.some((record) => record.path === '/package-expired')) {
            next()
          } else {
            // if user is admin or company owner, they can access subscription and package detail page
            if (user.isAdmin || user.isCompanyOwner) {
              if (
                to.matched.some(
                  (record) =>
                    record.path === '/subscription' ||
                    record.path === '/package-detail'
                )
              ) {
                return next()
              }
            }
            return next('/package-expired')
          }
        } else if (
          to.matched.some(
            (record) =>
              record.meta.forAdmin ||
              record.meta.forReleaseNoteAssignee ||
              record.meta.forCompanyOwner
          )
        ) {
          // Check if user is admin
          let assignee = store.getters['user/getReleaseNoteAssignee']

          if (assignee == null) {
            await store.dispatch('user/loadCurrentReleaseNoteAssignee')
            assignee = store.getters['user/getReleaseNoteAssignee']
          }
          if (to.meta.forAdmin || to.meta.forReleaseNoteAssignee) {
            if (user.isAdmin || user.id == assignee.id) {
              next()
            } else {
              next('/403')
            }
          } else if (to.meta.forCompanyOwner) {
            if (user.isCompanyOwner) {
              next()
            } else {
              next('/403')
            }
          }
        }
      }
    }
  }
  next()
})

const DEFAULT_TITLE = 'Rise'
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  })
})

export default router
