import axios from 'axios'
import JwtService from './jwt.service'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import * as qs from 'qs'
import store from '@/store'

const ApiService = {
  init() {
    axios.defaults.baseURL = process.env.VUE_APP_API_URL
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${JwtService.getToken()}`
    axios.defaults.headers.post['Content-Type'] =
      'application/x-www-form-urlencoded'
  },

  reInitAuthorization() {
    axios.defaults.baseURL = process.env.VUE_APP_API_URL
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${store.getters['auth/currentAccessToken']}`
  },

  reInitBaseURL() {
    axios.defaults.baseURL = process.env.VUE_APP_API_URL
  },

  setHeaderMultipartFormData() {
    axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'
  },

  resetHeader() {
    axios.defaults.headers.post['Content-Type'] =
      'application/x-www-form-urlencoded'
  },

  query(resource) {
    return axios.get(resource)
  },

  get(resource, params, headers, responseType) {
    return axios
      .get(`${resource}`, {
        headers: headers ? headers : axios.defaults.headers,
        responseType: responseType ? responseType : axios.defaults.responseType,
        params:
          params !== undefined ? snakecaseKeys(params, { deep: true }) : params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'brackets' })
        },
        transformResponse: [
          (data) => {
            try {
              return camelcaseKeys(JSON.parse(data), { deep: true })
            } catch (error) {
              console.log(error)
            }
          },
        ],
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.init()
          // return promise
          return store
            .dispatch('auth/getAccessToken')
            .then(() => {
              this.reInitAuthorization()
              // return get request again
              return this.get(resource, params)
            })
            .catch((err) => {
              console.log(err)
              store.dispatch('auth/logout')
              throw new Error('Somethings Wrong')
            })
        }
        throw err
      })
  },

  getFile(resource, params) {
    return axios
      .get(`${resource}`, {
        params:
          params !== undefined ? snakecaseKeys(params, { deep: true }) : params,
        responseType: 'blob',
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.init()
          // return promise
          return store
            .dispatch('auth/getAccessToken')
            .then(() => {
              this.reInitAuthorization()
              // return get file again
              return this.getFile(resource, params)
            })
            .catch((err) => {
              console.log(err)
              store.dispatch('auth/logout')
              throw new Error('Somethings Wrong')
            })
        }
        throw err
      })
  },

  post(resource, params, noSnakeCase, context, keycontext) {
    return axios
      .post(
        `${resource}`,
        !noSnakeCase ? snakecaseKeys(params, { deep: true }) : params,
        {
          onUploadProgress: (uploadEvent) => {
            let percentage = Math.round(
              (uploadEvent.loaded / uploadEvent.total) * 100
            )
            if (context != null && keycontext != null) {
              context.commit(keycontext, percentage)
            }
          },
          transformResponse: [
            (data) => {
              try {
                return camelcaseKeys(JSON.parse(data), { deep: true })
              } catch (error) {
                console.log(error)
              }
            },
          ],
        }
      )
      .catch((err) => {
        if (err.response.status === 401) {
          this.init()
          // return promise
          return store
            .dispatch('auth/getAccessToken')
            .then(() => {
              this.reInitAuthorization()
              // return post request
              return this.post(resource, params)
            })
            .catch((err) => {
              console.log(err)
              store.dispatch('auth/logout')
              throw new Error('Somethings Wrong')
            })
        }
        throw err
      })
  },

  update(resource, slug, params) {
    return axios.put(`${resource}/${slug}`, params)
  },

  put(resource, params, noSnakeCase) {
    return axios
      .put(
        `${resource}`,
        !noSnakeCase ? snakecaseKeys(params, { deep: true }) : params,
        {
          transformResponse: [
            (data) => {
              try {
                return camelcaseKeys(JSON.parse(data), { deep: true })
              } catch (error) {
                console.log(error)
              }
            },
          ],
        }
      )
      .catch((err) => {
        if (err.response.status === 401) {
          this.init()
          // return promise
          return store
            .dispatch('auth/getAccessToken')
            .then(() => {
              this.reInitAuthorization()
              // return put request
              return this.put(resource, params)
            })
            .catch((err) => {
              console.log(err)
              store.dispatch('auth/logout')
              throw new Error('Somethings Wrong')
            })
        }
        throw err
      })
  },

  delete(resource, params) {
    return axios
      .delete(resource, {
        params: params ? snakecaseKeys(params, { deep: true }) : null,
        transformResponse: [
          (data) => {
            try {
              return camelcaseKeys(JSON.parse(data), { deep: true })
            } catch (error) {
              console.log(error)
            }
          },
        ],
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.init()
          store
            .dispatch('auth/getAccessToken')
            .then(() => {
              this.reInitAuthorization()
              this.delete(resource, params)
            })
            .catch((err) => {
              console.log(err)
              store.dispatch('auth/logout')
              throw new Error('Somethings Wrong')
            })
        }
        throw err
      })
  },
}

export default ApiService
