import testRoutes from '../_test/routes'
export default [
  {
    path: '/version',
    component: () => import('../views/VersionPage'),
    name: 'Version',
  },
  {
    path: '/403',
    component: () => import('../views/ErrorPage/Forbidden.vue'),
    name: 'Forbidden',
    meta: {
      title: 'Forbidden',
    },
  },
  {
    path: '/package-expired',
    component: () => import('../views/ErrorPage/PackageExpired.vue'),
    name: 'Package Expired',
    meta: {
      title: 'Package Expired',
    },
  },
  {
    path: '*',
    component: () => import('../views/ErrorPage/NotFound.vue'),
    name: 'NotFound',
    meta: {
      title: 'Page Not Found',
    },
  },

  {
    path: '/company',
    meta: { requiresAuth: true, title: 'Rise | Company' },
    component: () => import('../views/Company/Company.vue'),
  },
  {
    path: '/create-company',
    meta: { requiresAuth: true, title: 'Rise | Create Company' },
    component: () => import('../views/Payment/CreatePayment.vue'),
    name: 'Create Company',
  },
  {
    path: '/receipt',
    component: () => import('../views/Payment/Receipt.vue'),
    name: 'Receipt',
  },
  {
    path: '/invoice-receipt',
    component: () => import('../views/Subscription/InvoiceReceipt.vue'),
    meta: {
      title: 'Invoice Receipt - Rise by Roketin',
    },
  },
  {
    path: '/join-company',
    component: () => import('../views/Company/Join-Company.vue'),
    name: 'Join-Company',
  },
  {
    path: '/auth/google/api.spacer.roketin.xyz/api/space-roketin/google/login',
    component: () => import('../views/Rise.vue'),
  },
  {
    path: '/auth',
    component: () => import('../layouts/authentication.vue'),
    children: [
      {
        path: 'login',
        component: () => import('../views/Authentication/Login/Login.vue'),
        name: 'Login',
        meta: {
          title: 'Login - Rise by Roketin',
        },
      },
      {
        path: 'registration',
        component: () =>
          import('../views/Authentication/Registration/Registration.vue'),
        meta: {
          title: 'Registration - Rise by Roketin',
        },
      },
      {
        path: 'password-reset',
        component: () => import('../views/Authentication/PasswordReset.vue'),
        meta: {
          title: 'Forgot Password - Rise by Roketin',
        },
      },
      {
        path: 'create-password/:token',
        component: () => import('../views/Authentication/CreatePassword.vue'),
        meta: {
          title: 'Create Password - Rise by Roketin',
        },
      },
      {
        path: 'reset-password/:token',
        component: () => import('../views/Authentication/NewPasswordReset.vue'),
        meta: {
          title: 'Reset Password - Rise by Roketin',
        },
      },
    ],
  },
  {
    path: '/',
    meta: { requiresAuth: true, title: 'Rise| Rise' },
    redirect: '/rise',
    component: () => import('../layouts/default.vue'),
    children: [
      testRoutes,
      {
        path: 'rise',
        component: () => import('../views/Rise.vue'),
        name: 'dashboard',
        meta: {
          title: 'Rise - Rise by Roketin',
        },
      },
      {
        path: 'face',
        component: () => import('../views/TestFaceApi/TestFaceApi'),
        name: 'face',
        meta: {
          title: 'Test Face API - Rise by Roketin',
        },
      },
      {
        path: 'attendance',
        component: () => import('../views/Attendance'),
        children: [
          {
            path: '',
            component: () =>
              import('../views/Attendance/AllEmployeeAttendance'),
            name: 'AttendanceAll',
            meta: {
              title: 'All Attendance - Attendance - Rise by Roketin',
            },
          },
          {
            path: 'my-team',
            component: () => import('../views/Attendance/EmployeeAttendance'),
            name: 'AttendanceEmployee',
            meta: {
              title: 'My team - Attendance - Rise by Roketin',
            },
          },
          {
            path: 'my-attendance',
            component: () => import('../views/Attendance/AttendanceTeam'),
            name: 'AttendanceTeam',
            meta: {
              title: 'My Attendance - Attendance - Rise by Roketin',
            },
          },
        ],
      },
      {
        path: 'people',
        component: () => import('../views/People'),
        name: 'people',
        meta: {
          title: 'People - Rise by Roketin',
        },
      },
      {
        path: 'dashboard',
        component: () => import('../views/Dashboard'),
        name: 'people',
        meta: {
          forAdmin: true,
          title: 'Dashboard - Rise by Roketin',
        },
      },

      {
        path: 'voucher',
        component: () => import('../views/InternalAdmin/Voucher.vue'),
        name: 'voucher',
        children: [
          {
            path: '',
            component: () =>
              import('../views/InternalAdmin/Voucher/ManageVoucher.vue'),
            name: 'ManageVoucher',
            meta: {
              title: 'Manage Voucher - Rise by Roketin',
            },
          },
          {
            path: 'add-voucher',
            component: () =>
              import('../views/InternalAdmin/Voucher/AddEditVoucher.vue'),
            name: 'AddVoucher',
          },
          {
            path: 'edit-voucher/:id',
            component: () =>
              import('../views/InternalAdmin/Voucher/AddEditVoucher.vue'),
            name: 'EditVoucher',
          },
        ],
      },
      {
        path: 'add-package/:subscriptionType',
        component: () => import('../views/InternalAdmin/AddEditPackage.vue'),
        name: 'AddPackage',
        meta: {
          title: 'Add Packages - Rise by Roketin',
        },
      },
      {
        path: 'edit-package/:subscriptionType/:id',
        component: () => import('../views/InternalAdmin/AddEditPackage.vue'),
        name: 'EditPackage',
        meta: {
          title: 'Edit Packages - Rise by Roketin',
        },
      },
      {
        path: '/packages/:subscriptionType',
        component: () => import('../views/InternalAdmin/ProductPackage.vue'),
        name: 'packages',
        meta: {
          title: 'Packages - Rise by Roketin',
        },
      },
      {
        path: '/companies',
        component: () => import('../views/InternalAdmin/Company.vue'),
        name: 'companies',
        children: [
          {
            path: '',
            component: () =>
              import('../views/InternalAdmin/Company/UserManagement.vue'),
            meta: {
              title: 'User Management - Rise by Roketin',
            },
          },
          {
            path: 'list-company',
            component: () =>
              import('../views/InternalAdmin/Company/ListCompany.vue'),
          },
        ],
      },
      {
        path: '/subscription',
        component: () => import('../views/Subscription.vue'),
        name: 'subscription',
        redirect: '/subscription/invoice',

        children: [
          {
            path: 'invoice',
            component: () => import('../views/Subscription/InvoiceData.vue'),
            meta: {
              title: 'Data Invoice - Rise by Roketin',
              forCompanyOwner: true,
            },
          },
          {
            path: 'package/:id',
            component: () =>
              import('../views/Subscription/SubscriptionChoosePackage.vue'),
            meta: {
              title: 'Rise by Roketin',
              forCompanyOwner: true,
            },
          },
          {
            path: 'refund',
            component: () => import('../views/Subscription/InvoiceRefund.vue'),
            meta: {
              title: 'Data Refund - Rise by Roketin',
              forCompanyOwner: true,
            },
          },
        ],
      },
      {
        path: 'package-detail',
        component: () =>
          import('../views/Subscription/SubscriptionPackageDetail.vue'),
        meta: {
          title: 'Package Detail - Rise by Roketin',
        },
      },
      {
        path: 'request',
        component: () => import('../views/Request'),
        name: 'request',
        children: [
          {
            path: 'overtime',
            component: () => import('../views/Request/Overtime'),
            children: [
              {
                path: '',
                component: () =>
                  import('../views/Request/Overtime/OvertimePersonal.vue'),
                name: 'Overtime Personal',
                meta: {
                  title: 'Overtime Personal - Rise by Roketin',
                },
              },
              {
                path: 'management',
                component: () =>
                  import('../views/Request/Overtime/OvertimeManagement.vue'),
                name: 'Overtime Management',
                meta: {
                  title: 'Overtime Management - Rise by Roketin',
                },
              },
            ],
          },
          {
            path: 'overtime/create',
            component: () => import('../views/Request/Overtime/CreateOvertime'),
            name: 'Create Overtime',
            meta: {
              title: 'Create Overtime - Overtime - Rise by Roketin',
            },
          },
          {
            path: 'time-off',
            component: () => import('../views/Request/TimeOff'),
            children: [
              {
                path: '',
                component: () =>
                  import('../views/Request/TimeOff/TimeOffPersonal.vue'),
                name: 'Time Off Personal',
                meta: {
                  title:
                    'Time Off Personal - Time Off Approval - Rise by Roketin',
                },
                children: [
                  {
                    path: 'create',
                    component: () =>
                      import('../views/Request/TimeOff/CreateTimeOff.vue'),
                    name: 'Create Time Off',
                    meta: {
                      title:
                        'Request Time Off - Time Off Approval - Rise by Roketin',
                    },
                  },
                ],
              },
              {
                path: 'management',
                component: () =>
                  import('../views/Request/TimeOff/TimeOffApproval.vue'),
                name: 'Time Off Approval',
                meta: {
                  title:
                    'Time Off Management - Time Off Approval - Rise by Roketin',
                },
              },
            ],
          },
          {
            path: 'change-schedule',
            component: () => import('../views/Request/ChangeSchedule'),
            children: [
              {
                path: '',
                component: () =>
                  import(
                    '../views/Request/ChangeSchedule/ChangeSchedulePersonal.vue'
                  ),
                name: 'Change Schedule Personal',
                meta: {
                  title:
                    'Change Schedule Personal - Change Schedule - Rise by Roketin',
                },
              },
              {
                path: 'management',
                component: () =>
                  import(
                    '../views/Request/ChangeSchedule/ChangeScheduleManagement.vue'
                  ),
                name: 'Change Schedule Management',
                meta: {
                  title:
                    'Change Schedule Management - Change Schedule - Rise by Roketin',
                },
              },
            ],
          },
          {
            path: 'reimbursement',
            component: () => import('../views/Request/Reimbursement'),
            children: [
              {
                path: '',
                component: () =>
                  import(
                    '../views/Request/Reimbursement/ReimbursementPersonal.vue'
                  ),
                name: 'Reimbursement Personal',
                meta: {
                  title:
                    'Reimbursement Personal - Reimbursement - Rise by Roketin',
                },
              },
              {
                path: 'management',
                component: () =>
                  import(
                    '../views/Request/Reimbursement/ReimbursementManagement.vue'
                  ),
                name: 'Reimbursement Management',
                meta: {
                  title:
                    'Reimbursement Management - Reimbursement - Rise by Roketin',
                },
              },
            ],
          },
          {
            path: 'attendance-adjustment',
            component: () => import('../views/Request/LogActivity'),
            children: [
              {
                path: '',
                component: () =>
                  import(
                    '../views/Request/LogActivity/LogActivityPersonal.vue'
                  ),
                name: 'Attendance Adjustment Personal',
                meta: {
                  title:
                    'Attendance Adjustment Personal - Attendance Adjustment - Rise by Roketin',
                },
              },
              {
                path: 'management',
                component: () =>
                  import(
                    '../views/Request/LogActivity/LogActivityManagement.vue'
                  ),
                name: 'Attendance Adjustment Management',
                meta: {
                  title:
                    'Attendance Adjustment Management - Attendance Adjustment - Rise by Roketin',
                },
              },
            ],
          },
          {
            path: 'activity-adjustment',
            component: () => import('../views/Request/ActivityAttendance'),
            children: [
              {
                path: '',
                component: () =>
                  import(
                    '../views/Request/ActivityAttendance/ActivityAttendancePersonal.vue'
                  ),
                name: 'Activity Adjustment Personal',
                meta: {
                  title:
                    'Activity Adjustment Personal - Activity Adjustment - Rise by Roketin',
                },
              },
              {
                path: 'management',
                component: () =>
                  import(
                    '../views/Request/ActivityAttendance/ActivityAttendanceManagement.vue'
                  ),
                name: 'Activity Adjustment Management',
                meta: {
                  title:
                    'Activity Adjustment Management - Activity Adjustment - Rise by Roketin',
                },
              },
            ],
          },
          {
            path: 'office-permission',
            component: () => import('../views/Request/WFOPermission'),
            children: [
              {
                path: '',
                component: () =>
                  import(
                    '../views/Request/WFOPermission/WFOPermissionPersonal.vue'
                  ),
                name: 'Office Permission Personal',
                meta: {
                  title:
                    'Personal Office Permission - Office Permission - Rise by Roketin',
                },
              },
              {
                path: 'management',
                component: () =>
                  import(
                    '../views/Request/WFOPermission/WFOPermissionManagement.vue'
                  ),
                name: 'Office Permission Management',
                meta: {
                  title:
                    'Office Permission Management - Office Permission - Rise by Roketin',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'employment',
        component: () => import('../views/Employment'),
        children: [
          {
            path: 'general',
            component: () =>
              import('../views/Employment/EmploymentGeneral.vue'),
            name: 'Employment General',
            meta: {
              title: 'General - Employment - Rise by Roketin',
            },
          },
          {
            path: 'contract',
            component: () =>
              import('../views/Employment/EmploymentContract.vue'),
            name: 'Employment Contract',
            meta: {
              title: 'Contract - Employment - Rise by Roketin',
            },
          },
          {
            path: 'archive',
            component: () =>
              import('../views/Employment/EmploymentArchive.vue'),
            name: 'Employment Archive',
            meta: {
              title: 'Archive - Employment - Rise by Roketin',
            },
          },
          {
            path: 'detail/:id',
            component: () => import('../views/Employment/EmploymentDetail.vue'),
            name: 'Employee Detail',
            meta: {
              title: 'Employee Detail - Employment - Rise by Roketin',
            },
          },
          {
            path: 'create',
            component: () => import('../views/Employment/FormCreatePeople.vue'),
            name: 'Create People',
            meta: {
              title: 'Create Employee - Employment - Rise by Roketin',
            },
          },
          {
            path: 'edit/:id',
            component: () => import('../views/Employment/FormEditPeople.vue'),
            name: 'Edit People',
            meta: {
              title: 'Edit Employee - Employment - Rise by Roketin',
            },
          },
        ],
      },
      {
        path: 'announcement',
        component: () => import('../views/Announcement/AnnouncementIndex.vue'),
        name: 'Announcement',
        meta: 'Announcement - Rise by Roketin',
      },
      {
        path: 'faq',
        beforeEnter() {
          location.href = process.env.VUE_APP_FAQ_URL
        },
        name: 'faq',
      },
      {
        path: 'settings',
        component: () => import('../views/Settings'),
        name: 'Settings',
        children: [
          {
            path: 'personal-info',
            component: () =>
              import('../views/Settings/PersonalInfo/PersonalInfo'),
            name: 'Personal Info',
            children: [
              {
                path: '/',
                component: () =>
                  import('../views/Settings/PersonalInfo/BasicInfo'),
                name: 'Personal Information',
                meta: {
                  title: 'Personal Info - Settings - Rise by Roketin',
                },
              },
              {
                path: 'edit',
                component: () =>
                  import('../views/Settings/PersonalInfo/EditPersonalInfo'),
                name: 'Edit Personal Information',
                meta: {
                  title: 'Edit Personal Info - Settings - Rise by Roketin',
                },
              },
              {
                path: 'edit-timezone',
                component: () =>
                  import('../views/Settings/PersonalInfo/EditTimezone'),
                name: 'Edit Timezone',
                meta: {
                  title: 'Edit Timezone - Settings - Rise by Roketin',
                },
              },
            ],
          },
          {
            path: 'calendar-settings',
            component: () => import('../views/Settings/CalendarSettings'),
            name: 'Calendar Setting',
            meta: {
              title: 'Calendar Settings - Settings - Rise by Roketin',
            },
          },
          {
            path: 'request-management',
            component: () => import('../views/Settings/RequestManagement'),
            name: 'Request Management',
            children: [
              {
                path: 'time-off-category',
                component: () =>
                  import('../views/Settings/RequestManagement/TimeOffCategory'),
                name: 'Time Off Category',
                meta: {
                  title: 'Time Off Category - Settings - Rise by Roketin',
                },
              },
              {
                path: 'reimbursement-category',
                component: () =>
                  import(
                    '../views/Settings/RequestManagement/ReimbursementCategory'
                  ),
                name: 'Reimbursement Category',
                meta: {
                  title: 'Reimbursement Category - Settings - Rise by Roketin',
                },
              },
            ],
          },
          {
            path: 'activity-category',
            component: () =>
              import('../views/Settings/ActivityCategory/ActivityCategory'),
            name: 'Activity Category',
            meta: {
              title: 'Activity Category - Settings - Rise by Roketin',
            },
          },
          {
            path: 'office',
            component: () => import('../views/Settings/Office/OfficeIndex'),
            name: 'Office',
            meta: {
              title: 'Office - Settings - Rise by Roketin',
            },
          },
          {
            path: 'final-approval',
            component: () => import('../views/Settings/FinalApproval'),
            name: 'Final Approval',
            meta: {
              title: 'Final Approval - Settings - Rise by Roketin',
            },
          },
          {
            path: 'list-request-personal-info',
            component: () => import('../views/Settings/PersonalInfo'),
            name: 'List Request Personal Info',
            meta: {
              forAdmin: true,
            },
            children: [
              {
                path: '/',
                component: () =>
                  import(
                    '../views/Settings/PersonalInfo/ListPersonalInfoApproval'
                  ),
                name: 'List Request Personal Info',
                meta: {
                  title:
                    'Final Request Personal Info - Settings - Rise by Roketin',
                },
              },
            ],
          },
          {
            path: 'list-request-personal-info',
            component: () => import('../views/Settings/PersonalInfo'),
            name: 'List Request Personal Info',
            meta: {
              forAdmin: true,
            },
            children: [
              {
                path: '/',
                component: () =>
                  import(
                    '../views' +
                      '/Settings/PersonalInfo/ListPersonalInfoApproval'
                  ),
                name: 'List Request Personal Info',
                meta: {
                  title:
                    'Final Request Personal Info - Settings - Rise by Roketin',
                },
              },
            ],
          },
          {
            path: 'allowance-setting',
            component: () =>
              import('../views/Settings/AllowanceSetting/AllowanceCategory'),
            name: 'Allowance Setting',
            meta: {
              title: 'Allowance Setting - Settings - Rise by Roketin',
            },
          },
          {
            path: 'general-setting',
            component: () => import('../views/Settings/GeneralSetting'),
            name: 'General Setting',
            meta: {
              title: 'General Setting - Settings - Rise by Roketin',
            },
          },
          {
            path: 'divisions',
            component: () => import('../views/Settings/Division'),
            name: 'Divisions',
            meta: {
              title: 'Divisions - Settings - Rise by Roketin',
            },
          },
          {
            path: 'quote-setting',
            component: () => import('../views/Settings/QuoteSetting'),
            name: 'Quote Setting',
            meta: {
              title: 'Quote Setting - Settings - Rise by Roketin',
            },
          },
          {
            path: 'change-password',
            component: () => import('../views/Settings/ChangePassword'),
            name: 'Change Password',
            meta: {
              title: 'Change Password - Settings - Rise by Roketin',
            },
          },
          {
            path: 'release-note',
            component: () => import('../views/Settings/ReleaseNote'),
            name: 'Release Note',
            meta: {
              title: 'Release Note - Settings - Rise by Roketin',
              forAdmin: true,
              forReleaseNoteAssignee: true,
            },
          },
        ],
      },
      {
        path: 'list-payment',
        component: () => import('../views/Payment/ListPayment.vue'),
        name: 'List Payment',
      },
    ],
  },
]
