import ApiService from '@/services/common/api.service'

const subscription = {
  namespaced: true,
  state: () => ({
    isLoading: false,
    invoiceData: [],
    currentPackage: {},
    invoiceRefund: [],
    currentVoucher: {},
  }),

  getters: {
    getIsLoading(state) {
      return state.isLoading
    },
    getInvoiceData(state) {
      return state.invoiceData
    },
    getCurrentPackage(state) {
      return state.currentPackage
    },
    getInvoiceRefund(state) {
      return state.invoiceRefund
    },
    getCurrentVoucher(state) {
      return state.getCurrentVoucher
    },
  },

  actions: {
    fetchInvoiceData(context, content) {
      const { parameter } = content
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(
          `/api/space-roketin/payment/v1/invoice/history?${parameter}&is_refund=0`
        ).then(
          (response) => {
            if (response && response.status === 200) {
              context.commit('setInvoiceData', [
                ...context.state.invoiceData,
                ...response.data.data,
              ])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    fetchCurrentPackage(context) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.get(
          'api/space-roketin/payment/v1/invoice/company/current-package'
        ).then(
          (response) => {
            if (response && response.status === 200) {
              context.commit('setCurrentPackage', response.data.data)
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    fetchInvoiceRefund(context, content) {
      const { parameter } = content
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(
          `/api/space-roketin/payment/v1/invoice/history?${parameter}&is_refund=1`
        ).then(
          (response) => {
            if (response && response.status === 200) {
              context.commit('setInvoiceRefund', [
                ...context.state.invoiceRefund,
                ...response.data.data,
              ])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    refundSubscription(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.post(
          `/api/space-roketin/payment/v1/payment/refund`,
          form,
          true
        ).then(
          (response) => {
            if (response && response.status === 200) {
              console.log(response)
              context.commit('setInvoiceRefund', [
                ...context.state.invoiceRefund,
                response.data.data,
              ])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', true)
            reject(error)
          }
        )
      })
    },

    validateVoucher(context, code) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(
          `/api/space-roketin/payment/v1/voucher/validate?voucher_code=${code}`
        ).then(
          (response) => {
            if (response && response.status === 200) {
              context.commit('setCurrentVoucher', response.data.data)
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },
  },

  mutations: {
    setInvoiceData(state, data) {
      state.invoiceData = data
    },
    setCurrentPackage(state, data) {
      state.currentPackage = data
    },
    setInvoiceRefund(state, data) {
      state.invoiceRefund = data
    },
    setLoading(state, data) {
      state.isLoading = data
    },
    setCurrentVoucher(state, data) {
      state.currentVoucher = data
    },
  },
}

export default subscription
