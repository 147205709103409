import Vue from 'vue'
import Vuex from 'vuex'

import changeSchedule from './modules/changeSchedule.module'
import overtimeModule from './modules/overtime.module'
import logActivity from './modules/logActivity.module'
import reimbursement from './modules/reimbursement.module'
import timeOff from './modules/timeOff'
import timeOffModule from './modules/timeOff.module'
import attendance from './modules/attendance.module'
import outstandingRequest from './modules/outstandingRequest.module'
import employment from './modules/employment.module'
import auth from './modules/auth'
import settings from './modules/settings.module'
import user from './modules/user.module'
import wfoPermission from './modules/wfoPermission.module'
import finalApproval from './modules/finalApproval.module'
import allowanceModule from './modules/allowance.module'
import activityAttendance from './modules/activityAttendance.module'
import announcement from './modules/announcement.module'
import quotes from './modules/quotes.module'
import releaseNoteModule from './modules/releaseNote.module'
import voucher from './modules/voucher.module'
import listCompanies from './modules/listCompanies.module'
import companies from './modules/companies.module'
import subscription from './modules/subscription.module'
import packages from './modules/package.module'
import activityCategory from './modules/activityCategory.module'

import firebaseToken from './modules/firebaseToken.module'
import notifications from './modules/notification.module'
import listPayment from './modules/listPayment.module'

Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    changeSchedule,
    overtimeModule,
    outstandingRequest,
    logActivity,
    reimbursement,
    timeOff,
    timeOffModule,
    attendance,
    employment,
    auth,
    settings,
    user,
    wfoPermission,
    finalApproval,
    firebaseToken,
    notifications,
    allowanceModule,
    activityAttendance,
    announcement,
    quotes,
    releaseNoteModule,
    voucher,
    listCompanies,
    listPayment,
    companies,
    subscription,
    packages,
    activityCategory,
  },
})

export default store
