import moment from 'moment-timezone'
const mixins = {
  methods: {
    textSubString(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix
      } else {
        return text
      }
    },
    getTimezonesOffset(tz) {
      return ' (GMT ' + moment.tz(tz).format('Z') + ')'
    },
    capitalizeFirstLetter(word) {
      if (word) {
        return word[0].toUpperCase() + word.slice(1)
      }
      return null
    },
  },
}

export default mixins
