import ApiService from '@/services/common/api.service'

const voucher = {
  namespaced: true,
  state: () => ({
    isLoading: false,
    voucherData: [],
    voucherDetail: {},
  }),

  getters: {
    getVoucherData(state) {
      return state.voucherData
    },
    getVoucherDetail(state) {
      return state.voucherDetail
    },
  },

  actions: {
    fetchVoucherData(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get('/api/space-roketin/payment/v1/admin-internal/voucher', {
          perPage: content.perPage,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          page: content.page,
        }).then(
          (response) => {
            if (response && response.status === 200) {
              context.commit('setVoucherData', [
                ...context.state.voucherData,
                ...response.data.data,
              ])
            }
            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            context.commit('setLoading', false)
            if (error.response.status === 403) {
              window.location.href = '/403'
            }
            reject(error)
          }
        )
      })
    },

    fetchVoucherDetail(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(
          `/api/space-roketin/payment/v1/admin-internal/voucher/${id}`
        ).then(
          (response) => {
            context.commit(
              'setVoucherDetail',
              context.state.voucherDetail,
              response.data.data
            )
            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    addVoucher(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.post(
          '/api/space-roketin/payment/v1/admin-internal/voucher',
          form,
          true
        ).then(
          (response) => {
            if (response && response.status === 201) {
              context.commit('setVoucherData', [
                ...context.state.voucherData,
                response.data.data,
              ])
            }
            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    editVoucher(context, data) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        const { id, form } = data

        ApiService.put(
          `/api/space-roketin/payment/v1/admin-internal/voucher/${id}`,
          form
        ).then(
          (response) => {
            if (response.status === 200 && context?.state?.voucherData) {
              let VoucherList = [...context.state.voucherData]
              let index = VoucherList.findIndex((t) => t.id === id)

              if (index >= 0) {
                VoucherList[index] = response.data.data
              }

              context.commit('setVoucherData', [...VoucherList])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    deleteVoucher(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.delete(
          `/api/space-roketin/payment/v1/admin-internal/voucher/${id}`
        ).then(
          (response) => {
            if (context?.state?.voucherData) {
              const data = context.state.voucherData.filter(
                (el) => el.id !== id
              )

              context.commit('setVoucherData', [...data])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', true)
            reject(error)
          }
        )
      })
    },
  },

  mutations: {
    setVoucherData(state, data) {
      state.voucherData = data
    },
    setVoucherDetail(state, data) {
      state.voucherDetail = data
    },
    setLoading(state, data) {
      state.isLoading = data
    },
  },
}

export default voucher
