import ApiService from '@/services/common/api.service'

const wfoPermission = {
  namespaced: true,
  state: () => ({
    errors: null,
    isLoading: false,
    wfoPermissionRequestData: [],
    wfoPermissionApprovalData: [],
    wfoPermissionDetail: null,
    wfoPermissionApprovalDetail: null,
  }),
  getters: {
    /**
     * Get wfo permission request data
     * @param {Object} state - stored object data for database
     * @returns overtime request data
     */
    getWFOPermissionRequestData(state) {
      return state.wfoPermissionRequestData
    },

    /**
     * Get wfo permission approval data
     * @param {Object} state - stored object data for database
     * @returns overtime request data
     */
    getWFOPermissionApprovalData(state) {
      return state.wfoPermissionApprovalData
    },

    /**
     * Get wfo permission detail data
     * @param {Object} state - stored object data for database
     * @returns overtime request data
     */
    getWFOPermissionDetail(state) {
      return state.wfoPermissionDetail
    },

    /**
     * Get wfo permission approval detail data
     * @param {Object} state - stored object data for database
     * @returns overtime request data
     */
    getWFOPermissionApprovalDetail(state) {
      return state.wfoPermissionApprovalDetail
    },
  },
  actions: {
    /**
     * Fetch WFO request data
     * @param {Object} context - object containing all getters and state of wfo request
     * @param {Object} content - request parameters object from parent component
     * @returns {Array} wfo request data
     */
    fetchWFOPermissionRequestData(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get('/api/space-roketin/wfo/request', {
          perPage: content.perPage,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          page: content.page,
          search: content.search,
        }).then(
          (response) => {
            if (response.status === 200) {
              if (content.fetchType === 'fetch') {
                context.commit('setWFOPermissionRequestData', [
                  ...response.data.data,
                ])
              } else {
                context.commit('setWFOPermissionRequestData', [
                  ...context.state.wfoPermissionRequestData,
                  ...response.data.data,
                ])
              }

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Fetch WFO approval data
     * @param {Object} context - object containing all getters and state of wfo approval
     * @param {Object} content - request parameters object from parent component
     * @returns {Array} wfo approval data
     */
    fetchWFOPermissionApprovalData(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(
          `/api/space-roketin/wfo/approval?filtering_conditions[]=${content.selectedStatus}`,
          {
            perPage: content.perPage,
            sortField: content.sortField,
            sortOrder: content.sortOrder,
            page: content.page,
            search: content.search,
            start_date: content.start_date,
            end_date: content.end_date,
            status: content.status,
          }
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setWFOPermissionApprovalData', [
                ...context.state.wfoPermissionApprovalData,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Fetch WFO request detail
     * @param {Object} context - object containing all getters and state of wfo request
     * @param {integer} id - selected detail wfo request id
     * @returns {Object} selected wfo request detail object
     */
    fetchWFOPermissionDetail(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(`/api/space-roketin/wfo/request/${id}`).then(
          (response) => {
            if (response.status === 200) {
              context.commit(
                'setWFOPermissionDetail',
                context.state.wfoPermissionDetail,
                response.data.data
              )
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Fetch WFO approval detail
     * @param {Object} context - object containing all getters and state of wfo approval
     * @param {integer} id - selected detail wfo approval id
     * @returns {Object} selected wfo approval detail object
     */
    fetchWFOPermissionApprovalDetail(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(`/api/space-roketin/wfo/approval/detail/${id}`).then(
          (response) => {
            if (response.status === 200) {
              context.commit(
                'setWFOPermissionApprovalDetail',
                context.state.wfoPermissionApprovalDetail,
                response.data.data
              )
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Create and save new wfo request
     * @param {Object} context - object containing all getters and state of wfo request
     * @param {Object} form - wfo request form data from parent component
     * @returns {Array} WFO request data from database
     */
    saveWFOPermission(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.setHeaderMultipartFormData()

        ApiService.post(`/api/space-roketin/wfo/request`, form, true).then(
          (response) => {
            context.commit('setWFOPermissionRequestData', [
              ...context.state.wfoPermissionRequestData,
              response.data.data,
            ])

            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    /**
     * Update wfo request
     * @param {Object} context - object containing all getters and state of time off request
     * @param {Object} data - time off request data containing selected id and form object
     * @returns {Array} Time off request data from database
     */
    updateWFOPermission(context, data) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.setHeaderMultipartFormData()

        const { form, id } = data

        ApiService.post(
          `/api/space-roketin/wfo/request/${id}`,
          form,
          true
        ).then(
          (response) => {
            context.commit('setWFOPermissionRequestData', response.data.data)

            context.commit('setLoading', true)

            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    /**
     * Update WFO request
     * @param {Object} context - object containing all getters and state of wfo request
     * @param {Object} content - object containing id, status, and pic id
     * @returns {Array} wfo request data from database
     */
    updateWFOPermissionApproval(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)

        const { id, status, reportToId } = content

        ApiService.put(`/api/space-roketin/wfo/approval/${id}`, {
          status: status,
        }).then(
          (response) => {
            if (
              response.status === 200 &&
              context?.state?.wfoPermissionApprovalData
            ) {
              let wfoPermissionApprovalList = [
                ...context.state.wfoPermissionApprovalData,
              ]

              let index = wfoPermissionApprovalList.findIndex(
                (t) => t.id === id
              )

              if (index >= 0) {
                wfoPermissionApprovalList[index].status = status

                let historyIndex = wfoPermissionApprovalList[
                  index
                ].history.findIndex((h) => h.reportToId === reportToId)

                if (historyIndex >= 0) {
                  // update history
                  wfoPermissionApprovalList[index].history[
                    historyIndex
                  ].status = status
                }
              }

              context.commit('updateApprovalStatus', [
                ...wfoPermissionApprovalList,
              ])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Cancel wfo approval data
     * @param {Object} context - object containing all getters and state of wfo approval
     * @param {integer} id - selected detail wfo approval id
     * @returns {Object} selected wfo approval data
     */
    cancelWFOPermissionApproval(context, id) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)

        ApiService.put(`/api/space-roketin/wfo/approval/${id}/cancel`, {}).then(
          (response) => {
            if (
              response.status === 200 &&
              context?.state?.wfoPermissionApprovalData
            ) {
              context.commit('cancelAllApprovals', id)

              context.commit('setLoading', true)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', true)
            reject(error)
          }
        )
      })
    },

    /**
     * Update WFO Status in Bulk
     * @param {Object} context - object containing all getters and state of wfo approval
     * @param {Object} content - object containing selected ids and status
     * @returns {Array} updated wfo approval request status
     */
    massUpdateWFOPermissionApproval(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        const { status, ids } = content

        ApiService.put('/api/space-roketin/wfo/approval/mass-update', {
          status: status,
          ids: ids,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('massUpdateApprovalStatus', {
                status,
                ids,
              })
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Cancel wfo request data
     * @param {Object} context - object containing all getters and state of wfo request
     * @param {integer} id - selected detail wfo request id
     * @returns {Object} selected wfo request data
     */
    cancelWFOPermissionRequest(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.setHeaderMultipartFormData()

        ApiService.post(
          `/api/space-roketin/wfo/request/${id}`,
          {
            _METHOD: 'PUT',
            status: 'canceled',
          },
          true
        ).then(
          (response) => {
            if (
              response.status === 200 &&
              context?.state?.wfoPermissionRequestData
            ) {
              let wfoPermissionRequestList = [
                ...context.state.wfoPermissionRequestData,
              ]

              let index = wfoPermissionRequestList.findIndex((t) => t.id === id)

              if (index >= 0) {
                wfoPermissionRequestList[index] = response.data.data
              }

              context.commit('setWFOPermissionRequestData', [
                ...wfoPermissionRequestList,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
  },
  mutations: {
    /**
     * Set WFO request data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setWFOPermissionRequestData(state, data) {
      state.wfoPermissionRequestData = data
    },

    /**
     * Set WFO approval data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setWFOPermissionApprovalData(state, data) {
      state.wfoPermissionApprovalData = data
    },

    /**
     * Set WFO detail data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setWFOPermissionDetail(state, data) {
      state.wfoPermissionDetail = data
    },

    /**
     * Set WFO approval detail data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setWFOPermissionApprovalDetail(state, data) {
      state.wfoPermissionApprovalData = data
    },

    /**
     * Update WFO approval status
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    updateApprovalStatus(state, data) {
      state.wfoPermissionApprovalData = data
    },

    /**
     * Set multiple WFO approval status data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    massUpdateApprovalStatus(state, data) {
      const { status, ids } = data
      let wfoPermissionApprovalList = [...state.wfoPermissionApprovalData]

      wfoPermissionApprovalList.forEach((t) => {
        if (ids.includes(t.id)) {
          t.status = status
        }
      })
      state.wfoPermissionApprovalData = wfoPermissionApprovalList
    },

    /**
     * Cancel WFO approval data
     * @param {Object} state - stored object data for database
     * @param {integer} id - request id
     */
    cancelAllApprovals(state, id) {
      let wfoPermissionApprovalData = [...state.wfoPermissionApprovalData]
      let index = wfoPermissionApprovalData.findIndex((t) => t.id === id)
      if (index >= 0) {
        wfoPermissionApprovalData[index].status = 'canceled'

        // update all history's status to canceled
        for (let key in wfoPermissionApprovalData[index].history) {
          wfoPermissionApprovalData[index].history[key].status = 'canceled'
        }
        state.wfoPermissionApprovalData = wfoPermissionApprovalData
      }
    },

    /**
     * Set loading state
     * @param {Object} state - stored object data for database
     * @param {Object} data - loading state
     */
    setLoading(state, data) {
      state.isLoading = data
    },
  },
}

export default wfoPermission
