import ApiService from '@/services/common/api.service'

const activityAttendance = {
  namespaced: true,
  state: () => ({
    errors: null,
    isLoading: false,
    activityAttendancePersonal: [],
    activityAttendanceTeam: [],
    activityAttendanceRise: [],
    activityAttendanceDetail: null,
    activityAttendanceApprovalDetail: null,
  }),
  getters: {
    /**
     * Get activity attendance request data
     * @param {Object} state - stored object data for database
     * @returns activity attendance request data
     */
    getActivityAttendancePersonal(state) {
      return state.activityAttendancePersonal
    },

    /**
     * Get activity attendance request data
     * @param {Object} state - stored object data for database
     * @returns activity attendance approval data
     */
    getActivityAttendanceTeam(state) {
      return state.activityAttendanceTeam
    },

    /**
     * Get activity attendance rise data
     * @param {Object} state - stored object data for database
     * @returns activity attendance rise data
     */
    getActivityAttendanceRise(state) {
      return state.activityAttendanceRise
    },

    /**
     * Get activity attendance detail data
     * @param {Object} state - stored object data for database
     * @returns activity attendance detail data
     */
    getActivityAttendanceDetail(state) {
      return state.activityAttendanceDetail
    },

    /**
     * Get activity attendance approval detail data
     * @param {Object} state - stored object data for database
     * @returns activity attendance approval detail data
     */
    getActivityAttendanceApprovalDetail(state) {
      return state.activityAttendanceApprovalDetail
    },
  },

  actions: {
    /**
     * Fetch activity attendance request data
     * @param {Object} context - object containing all getters and state of activity attendance request
     * @param {Object} content - request parameters object from parent component
     * @returns {Array} activity attendance request data
     */
    fetchActivityAttendancePersonal(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(
          '/api/space-roketin/v3/attendance-activity-adjustment/request',
          {
            sortField: content.sortField,
            sortOrder: content.sortOrder,
            perPage: content.perPage,
            page: content.page,
            search: content.search,
          }
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setActivityAttendancePersonal', [
                ...context.state.activityAttendancePersonal,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Fetch activity attendance team data
     * @param {Object} context - object containing all getters and state of activity attendance team
     * @param {Object} content - request parameters object from parent component
     * @returns {Array} activity attendance team data
     */
    fetchActivityAttendanceTeam(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(
          `/api/space-roketin/v3/attendance-activity-adjustment/approval?filtering_conditions[]=${content.selectedStatus}`,
          {
            perPage: content.perPage,
            sortField: content.sortField,
            sortOrder: content.sortOrder,
            page: content.page,
            search: content.search,
            start_date: content.start_date,
            end_date: content.end_date,
            status: content.status,
          }
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setActivityAttendanceTeam', [
                ...context.state.activityAttendanceTeam,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Fetch activity attendance detail data
     * @param {Object} context - object containing all getters and state of activity attendance detail
     * @param {integer} detailId - selected detail activity attendance request id
     * @returns {Array} activity attendance detail data
     */
    fetchActivityAttendanceDetail(context, detailId) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.get(
          `/api/space-roketin/v3/attendance-activity-adjustment/request/${detailId}`
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit(
                'setActivityAttendanceDetail',
                context.state.activityAttendanceDetail,
                response.data.data
              )
            }
            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Fetch activity attendance approval detail data
     * @param {Object} context - object containing all getters and state of activity attendance detail
     * @param {integer} id - selected detail activity attendance approval id
     * @returns {Array} activity attendance approval detail data
     */
    fetchActivityAttendanceApprovalDetail(context, id) {
      return new Promise((resolve, reject) => {
        ApiService.get(
          `/api/space-roketin/v3/attendance-activity-adjustment/approval/detail/${id}`
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit(
                'setActivityAttendanceApprovalDetail',
                context.state.activityAttendanceDetail,
                response.data.data
              )
            }
            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Fetch activity attendance rise dashboard data
     * @param {Object} context - object containing all getters and state of activity attendance rise dashboard
     * @returns {Array} activity attendance rise dashboard data
     */
    fetchActivityAttendanceRise(context) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get('/api/space-roketin/attendance-activity/universe').then(
          (response) => {
            if (response.status === 200) {
              context.commit('setActivityAttendanceRise', [
                ...context.state.activityAttendanceRise,
                ...response.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Update overtime request
     * @param {Object} context - object containing all getters and state of overtime request
     * @param {Object} data - overtime request data containing selected id, status, and pic id
     * @returns {Array} Overtime request data from database
     */
    updateLogActivityApprovalRequests(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)

        const { id, status, reportToId } = content

        ApiService.put(
          `/api/space-roketin/v3/attendance-activity-adjustment/approval/${id}`,
          {
            status: status,
          }
        ).then(
          (response) => {
            if (
              response.status === 200 &&
              context?.state?.activityAttendanceTeam
            ) {
              let activityResponseTeamList = [
                ...context.state.activityAttendanceTeam,
              ]

              let index = activityResponseTeamList.findIndex((t) => t.id === id)

              if (index >= 0) {
                activityResponseTeamList[index].status = status

                let historyIndex = activityResponseTeamList[
                  index
                ].history.findIndex((h) => h.reportToId === reportToId)

                if (historyIndex >= 0) {
                  // update history
                  activityResponseTeamList[index].history[
                    historyIndex
                  ].status = status
                }
              }
              context.commit('updateApprovalStatus', [
                ...activityResponseTeamList,
              ])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * get total activity duration on rise dashboard
     * @returns {Time String} total activity duration
     */
    getTotalActivityDuration() {
      return new Promise((resolve, reject) => {
        ApiService.get('/api/space-roketin/attendance-activity/universe').then(
          (response) => {
            response.data[0].totalActivityDurationPerDay
              ? response.data[0].totalActivityDurationPerDay
              : 0
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    // cancel approval Request
    /**
     * Cancel Activity adjustment approval
     * @param {Object} context - object containing all getters and state of overtime request
     * @param {integer} id - selected activity adjustment approval id
     * @returns {Object} selected activity adjustment approval object
     */
    cancelActivityApproval(context, id) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)

        ApiService.put(
          `/api/space-roketin/v3/attendance-activity-adjustment/approval/${id}/cancel`,
          {}
        ).then(
          (response) => {
            if (
              response.status === 200 &&
              context?.state?.activityAttendanceTeam
            ) {
              context.commit('cancelAllApprovals', id)

              context.commit('setLoading', true)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', true)
            reject(error)
          }
        )
      })
    },

    /**
     * Update Approval Status in Bulk
     * @param {Object} context - object containing all getters and state of activity adjustment request
     * @param {Object} content - object containing selected ids and status
     * @returns {Array} updated activity adjustment approval request status
     */
    massUpdateActivityApprovalRequests(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        const { status, approvalIds } = content

        ApiService.put(
          '/api/space-roketin/v3/attendance-activity-adjustment/approval/bulk',
          { status: status, approval_ids: approvalIds }
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit('massUpdateApprovalStatus', {
                status,
                approvalIds,
              })
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading, false')
            reject(error)
          }
        )
      })
    },
  },

  mutations: {
    /**
     * Set activity adjustment personal data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setActivityAttendancePersonal(state, data) {
      state.activityAttendancePersonal = data
    },

    /**
     * Set activity adjustment team data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setActivityAttendanceTeam(state, data) {
      state.activityAttendanceTeam = data
    },

    /**
     * Set activity adjustment detail data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setActivityAttendanceDetail(state, data) {
      state.activityAttendanceTeam = data
    },

    /**
     * Set activity adjustment approval detail data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setActivityAttendanceApprovalDetail(state, data) {
      state.activityAttendanceApprovalDetail = data
    },

    /**
     * Set activity adjustment rise dashboard data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setActivityAttendanceRise(state, data) {
      // add isLoading property to each data to controll toggle play/pause loading state
      data.forEach((d) => {
        d.data.forEach((t) => (t.isLoading = false))
      })
      state.activityAttendanceRise = data
    },

    /**
     * Update activity adjustment approval data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    updateApprovalStatus(state, data) {
      state.activityAttendanceTeam = data
    },

    /**
     * Set multiple activity adjustment approval status
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data containing status and approval ids
     */
    massUpdateApprovalStatus(state, data) {
      const { status, approvalIds } = data
      let activityResponseTeamList = [...state.activityAttendanceTeam]

      activityResponseTeamList.forEach((t) => {
        if (approvalIds.includes(t.id)) {
          t.status = status
        }
      })
      state.activityAttendanceTeam = activityResponseTeamList
    },

    /**
     * Cancel activity adjustment approval request
     * @param {Object} state - stored object data for database
     * @param {integer} id - request id
     */
    cancelAllApprovals(state, id) {
      let activityAttendanceTeam = [...state.activityAttendanceTeam]
      let index = activityAttendanceTeam.findIndex((t) => t.id === id)
      if (index >= 0) {
        activityAttendanceTeam[index].status = 'canceled'

        // update all history's status to canceled
        for (let key in activityAttendanceTeam[index].history) {
          activityAttendanceTeam[index].history[key].status = 'canceled'
        }
        state.activityAttendanceTeam = activityAttendanceTeam
      }
    },

    /**
     * Set loading state
     * @param {Object} state - stored object data for database
     * @param {Object} data - loading state
     */
    setLoading(state, data) {
      state.isLoading = data
    },
  },
}

export default activityAttendance
