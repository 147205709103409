import ApiService from '@/services/common/api.service'

const listCompanies = {
  namespaced: true,
  state: () => ({
    errors: null,
    isLoading: false,
    listCompaniesData: [],
  }),
  getters: {
    getListCompaniesData(state) {
      return state.listCompaniesData
    },
  },
  actions: {
    fetchListCompaniesData(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.get(
          `/api/space-roketin/admin-internal/company?${content.filterPackage}&${content.filterPackage2}&${content.filterPackage3}&${content.filterPackage4}&${content.filterStatus}&${content.filterStatus2}`,
          {
            perPage: content.perPage,
            sortField: content.sortField,
            sortOrder: content.sortOrder,
            page: content.page,
            search: content.search,
            // filterPackage: content.filterPackage,
            // filterStatus: content.filterStatus,
          }
        ).then(
          (response) => {
            if (response.status === 200) {
              if (content.isFiltering) {
                context.commit('setListCompaniesData', response.data.data)
              } else {
                context.commit('setListCompaniesData', [
                  ...context.state.listCompaniesData,
                  ...response.data.data,
                ])
              }

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },
  },

  mutations: {
    setListCompaniesData(state, data) {
      state.listCompaniesData = data
    },
    setLoading(state, data) {
      state.isLoading = data
    },
  },
}

export default listCompanies
