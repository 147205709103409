import ApiService from '@/services/common/api.service'

const overtime = {
  namespaced: true,
  state: () => ({
    errors: null,
    isLoading: false,
    overtimeRequestData: [],
    overtimeApprovalData: [],
    overtimeDetail: null,
    overtimeApprovalDetail: null,
  }),
  getters: {
    /**
     * Get overtime request data
     * @param {Object} state - stored object data for database
     * @returns overtime request data
     */
    getOvertimeRequestData(state) {
      return state.overtimeRequestData
    },

    /**
     * Get overtime approval request data
     * @param {Object} state - stored object data for database
     * @returns overtime approval data
     */
    getOvertimeApprovalData(state) {
      return state.overtimeApprovalData
    },

    /**
     * Get overtime detail data
     * @param {Object} state - stored object data for database
     * @returns overtime detail data
     */
    getOvertimeDetail(state) {
      return state.overtimeDetail
    },

    /**
     * Get overtime approval detail data
     * @param {Object} state - stored object data for database
     * @returns overtime approval request data
     */
    getOvertimeApprovalDetail(state) {
      return state.overtimeApprovalDetail
    },
  },

  actions: {
    /**
     * Fetch overtime request data
     * @param {Object} context - object containing all getters and state of overtime request
     * @param {Object} content - request parameters object from parent component
     * @returns {Array} overtime request data
     */
    fetchOvertimeRequestData(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get('/api/space-roketin/overtime/v3/request', {
          perPage: content.perPage,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          page: content.page,
          search: content.search,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setOvertimeRequestData', [
                ...context.state.overtimeRequestData,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Fetch overtime approval data
     * @param {Object} context - object containing all getters and state of overtime request
     * @param {Object} content - request parameters object from parent component
     * @returns {Array} overtime approval data
     */
    fetchOvertimeApprovalData(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(
          `/api/space-roketin/overtime/v3/approval?sort_field=updated_at&sort_order=asc&filtering_conditions[]=${content.selectedStatus}`,
          {
            perPage: content.perPage,
            sortField: content.sortField,
            sortOrder: content.sortOrder,
            page: content.page,
            search: content.search,
            start_date: content.start_date,
            end_date: content.end_date,
            status: content.status,
          }
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setOvertimeApprovalData', [
                ...context.state.overtimeApprovalData,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Fetch selected overtime detail
     * @param {Object} context - object containing all getters and state of overtime request
     * @param {integer} id - selected detail overtime request id
     * @returns {Object} selected overtime request detail object
     */
    fetchOvertimeDetail(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(`/api/space-roketin/overtime/v3/request/${id}`).then(
          (response) => {
            if (response.status === 200) {
              context.commit(
                'setOvertimeDetail',
                context.state.overtimeDetail,
                response.data.data
              )
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Fetch selected approval overtime detail
     * @param {Object} context - object containing all getters and state of overtime request
     * @param {integer} id - selected detail overtime approval id
     * @returns {Object} selected overtime approval detail object
     */
    fetchOvertimeApprovalDetail(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(
          `/api/space-roketin/overtime/v3/approval/detail/${id}`
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit(
                'setOvertimeApprovalDetail',
                context.state.overtimeApprovalDetail,
                response.data.data
              )
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Create and save new overtime request
     * @param {Object} context - object containing all getters and state of overtime request
     * @param {Object} form - overtime request form data from parent component
     * @returns {Array} Overtime request data from database
     */
    saveOvertime(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.post(
          '/api/space-roketin/overtime/v3/request',
          form,
          true
        ).then(
          (response) => {
            context.commit('setOvertimeRequestData', [
              ...context.state.overtimeRequestData,
              response.data.data,
            ])

            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    /**
     * Update overtime request
     * @param {Object} context - object containing all getters and state of overtime request
     * @param {Object} data - overtime request data containing selected id and form object
     * @returns {Array} Overtime request data from database
     */
    updateOvertimeRequest(context, data) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.setHeaderMultipartFormData()

        const { id, form } = data

        ApiService.post(
          `/api/space-roketin/overtime/v3/request/${id}`,
          form,
          true
        ).then(
          (response) => {
            if (
              response.status === 200 &&
              context?.state?.overtimeRequestData
            ) {
              let OvertimeRequestList = [...context.state.overtimeRequestData]

              let index = OvertimeRequestList.findIndex((t) => t.id === id)

              if (index >= 0) {
                OvertimeRequestList[index] = response.data.data
              }

              context.commit('setOvertimeRequestData', [...OvertimeRequestList])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    /**
     * Update overtime approval request
     * @param {Object} context - object containing all getters and state of overtime request
     * @param {Object} content - object containing id, status, and pic id
     * @returns {Array} overtime approval data from database
     */
    updateOvertimeApproval(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)

        const { id, status, reportToId } = content

        ApiService.put(`/api/space-roketin/overtime/v3/approval/${id}`, {
          status: status,
        }).then(
          (response) => {
            if (
              response.status === 200 &&
              context?.state?.overtimeApprovalData
            ) {
              let overtimeApprovalList = [...context.state.overtimeApprovalData]

              let index = overtimeApprovalList.findIndex((t) => t.id === id)

              if (index >= 0) {
                overtimeApprovalList[index].status = status

                let historyIndex = overtimeApprovalList[
                  index
                ].history.findIndex((h) => h.reportToId === reportToId)

                if (historyIndex >= 0) {
                  overtimeApprovalList[index].history[
                    historyIndex
                  ].status = status
                }
              }

              context.commit('updateApprovalStatus', [...overtimeApprovalList])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Cancel overtime approval request
     * @param {Object} context - object containing all getters and state of overtime request
     * @param {integer} id - selected detail overtime request id
     * @returns {Object} selected overtime request detail object
     */
    cancelOvertimeApproval(context, id) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)

        ApiService.put(
          `/api/space-roketin/overtime/v3/approval/${id}/cancel`,
          {}
        ).then(
          (response) => {
            if (
              response.status === 200 &&
              context?.state?.overtimeApprovalData
            ) {
              context.commit('cancelAllApprovals', id)

              context.commit('setLoading', true)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', true)
            reject(error)
          }
        )
      })
    },

    /**
     * Update Approval Status in Bulk
     * @param {Object} context - object containing all getters and state of overtime request
     * @param {Object} content - object containing selected ids and status
     * @returns {Array} updated overtime approval request status
     */
    massUpdateOvertimeApproval(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        const { status, ids } = content

        ApiService.put('/api/space-roketin/overtime/v3/approval/bulk', {
          status: status,
          ids: ids,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('massUpdateApprovalStatus', {
                status,
                ids,
              })
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Upload report data
     * @param {Object} context - object containing all getters and state of overtime request
     * @param {Object} form - Form object
     * @returns report response
     */
    uploadReport(context, form) {
      const formData = new FormData()
      formData.append('files[]', form.files)
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.post(
          `/api/space-roketin/overtime/v3/request/${form.id}/report`,
          formData,
          true
        ).then(
          (response) => {
            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    /**
     * Complete Overtime Approval
     * @param {Object} context - object containing all getters and state of overtime request
     * @param {integer} id - selected overtime request id
     * @returns {Array} overtime approval data
     */
    completeOvertimeApproval(context, id) {
      return new Promise((resolve, reject) => {
        ApiService.put(
          `/api/space-roketin/overtime/v3/approval/${id}/complete`,
          {}
        ).then(
          (response) => {
            if (
              response.status === 200 &&
              context?.state?.overtimeApprovalData
            ) {
              context.commit('cancelAllApprovals', id)

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },
  },

  mutations: {
    /**
     * Set overtime request data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setOvertimeRequestData(state, data) {
      state.overtimeRequestData = data
    },

    /**
     * Set overtime approval data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setOvertimeApprovalData(state, data) {
      state.overtimeApprovalData = data
    },

    /**
     * Update overtime approval data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    updateApprovalStatus(state, data) {
      state.overtimeApprovalData = data
    },

    /**
     * Set overtime detail data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setOvertimeDetail(state, data) {
      state.overtimeDetail = data
    },

    /**
     * Set overtime approval detail data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setOvertimeApprovalDetail(state, data) {
      state.overtimeApprovalDetail = data
    },

    /**
     * Set multiple overtime requests status
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data containing status and ids
     */
    massUpdateApprovalStatus(state, data) {
      const { status, ids } = data
      let overtimeApprovalList = [...state.overtimeApprovalData]

      overtimeApprovalList.forEach((t) => {
        if (ids.includes(t.id)) {
          t.status = status
        }
      })
      state.overtimeApprovalData = overtimeApprovalList
    },

    /**
     * Cancel overtime approval request
     * @param {Object} state - stored object data for database
     * @param {integer} id - request id
     */
    cancelAllApprovals(state, id) {
      let overtimeApprovalData = [...state.overtimeApprovalData]
      let index = overtimeApprovalData.findIndex((t) => t.id === id)
      if (index >= 0) {
        overtimeApprovalData[index].status = 'canceled'

        // update all history's status to canceled
        for (let key in overtimeApprovalData[index].history) {
          overtimeApprovalData[index].history[key].status = 'canceled'
        }
        state.overtimeApprovalData = overtimeApprovalData
      }
    },

    /**
     * Set loading state
     * @param {Object} state - stored object data for database
     * @param {Object} data - loading state
     */
    setLoading(state, data) {
      state.isLoading = data
    },
  },
}

export default overtime
