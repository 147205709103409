import ApiService from '@/services/common/api.service'
import JwtService from '@/services/common/jwt.service'
import router from '@/router'
import store from '@/store'
import { deleteFCMToken } from '../../services/firebase/firebase-messaging'
import Cookies from 'js-cookie'

const auth = {
  namespaced: true,
  state: () => ({
    errors: null,
    message: null,
    user: {},
    loggedIn: !!JwtService.getToken(),
    accessToken: null,
  }),
  getters: {
    currentUser(state) {
      return state.user
    },
    isLoggedIn(state) {
      return state.loggedIn
    },
    currentAccessToken(state) {
      return state.accessToken
    },
  },
  actions: {
    login(context, credentials) {
      return new Promise((resolve, reject) => {
        ApiService.post('/api/space-roketin/login', {
          username: credentials.email,
          password: credentials.password,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setAccessToken', response.data.data.accessToken)
              context.commit('setAuth', {
                user: response.data.data.user,
              })

              Cookies.set('id_token_space_id', response.data.data.refreshToken)

              if (!response.data.data.user.isRegistered) {
                router.push({ path: '/company' })
              } else {
                router.push({ path: '/rise' })
              }

              // router.push({ path: credentials.redirect })

              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    googleSocialLogin(context, credentials) {
      return new Promise((resolve, reject) => {
        ApiService.post('/api/space-roketin/google/login', credentials).then(
          (response) => {
            if (response.status === 200) {
              console.log('Vuex', response.data.data)
              context.commit('setAccessToken', response.data.data.accessToken)
              context.commit('setAuth', {
                user: response.data.data.user,
              })

              Cookies.set('id_token_space_id', response.data.data.refreshToken)

              if (!response.data.data.user.isRegistered) {
                router.push({ path: '/company' })
              } else {
                router.push({ path: '/rise' })
              }

              resolve(response)
            }
          },
          (error) => {
            localStorage.setItem('otpToken', error.response.data.data.otpToken)
            reject(error)
          }
        )
      })
    },

    msSocialLogin(context, credentials) {
      return new Promise((resolve, reject) => {
        ApiService.post(
          '/api/space-roketin/microsoft/login-web',
          credentials
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setAccessToken', response.data.data.accessToken)
              context.commit('setAuth', {
                user: response.data.data.user,
              })

              Cookies.set('id_token_space_id', response.data.data.refreshToken)

              if (!response.data.data.user.isRegistered) {
                router.push({ path: '/company' })
              } else {
                router.push({ path: '/rise' })
              }

              resolve(response)
            }
          },
          (error) => {
            console.log(error?.response.data.message)
            reject(error)
          }
        )
      })
    },
    async logout(context) {
      await deleteFCMToken()
      context.commit('purgeAuth')
      router.push({ path: '/auth/login' })
      router.go(0)
    },

    register(context, form) {
      return new Promise((resolve, reject) => {
        //  /api/space-roketin/register
        ApiService.post('/api/space-roketin/register', form).then(
          (response) => {
            if (response.status == 200) {
              localStorage.setItem('otpToken', response.data.data.otpToken)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    getAccessToken(context) {
      return new Promise((resolve, reject) => {
        ApiService.reInitBaseURL()

        ApiService.post('/api/space-roketin/refresh/token', {}).then(
          (response) => {
            if (response.status == 200) {
              context.commit('setAccessToken', response.data.data.accessToken)
              context.commit('setRefreshToken', response.data.data.refreshToken)

              Cookies.set('id_token_space_id', response.data.data.refreshToken)

              if (!response.data.data.user.isRegistered) {
                router.push({ path: '/company' })
              }

              resolve(response)
            }
          },
          (error) => {
            store.dispatch('auth/logout')
            reject(error)
          }
        )
      })
    },
  },

  mutations: {
    setError(state, error) {
      state.errors = error
    },
    setAuth(state, user) {
      state.loggedIn = true
      state.user = user
      state.errors = {}
    },
    setAccessToken(state, data) {
      state.accessToken = data
    },
    setRefreshToken(state, data) {
      JwtService.saveToken(data)
    },

    purgeAuth(state) {
      state.loggedIn = false
      state.user = {}
      state.errors = {}
      state.accessToken = ''
      state.refreshToken = ''
      JwtService.destroyToken()
      Cookies.remove('id_token_space_id')
    },
  },
}

export default auth
