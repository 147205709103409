import ApiService from '@/services/common/api.service'

const packages = {
  namespaced: true,
  state: () => ({
    isLoading: false,
    packagesData: [],
    packagesDetail: {},
  }),

  getters: {
    getPackagesData(state) {
      return state.packagesData
    },
    getPackagesDetail(state) {
      return state.packagesDetail
    },
  },

  //   /api/space-roketin/payment/v1/admin-internal/packages

  actions: {
    fetchPackagesData(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        // below here wrong API, fix it
        ApiService.get('/api/space-roketin/payment/v1/admin-internal/package', {
          subscriptionType: content.subscriptionType,
          perPage: content.perPage,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          page: content.page,
        }).then(
          (response) => {
            if (response && response.status === 200) {
              context.commit('setPackagesData', [
                ...context.state.packagesData,
                ...response.data.data,
              ])
            }
            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            context.commit('setLoading', false)
            if (error.response.status === 403) {
              window.location.href = '/403'
            }
            reject(error)
          }
        )
      })
    },

    fetchPackagesDetail(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(
          `/api/space-roketin/payment/v1/admin-internal/package/${id}`
        ).then(
          (response) => {
            context.commit(
              'setPackagesDetail',
              context.state.packagesDetail,
              response.data.data
            )
            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    addPackages(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.post(
          '/api/space-roketin/payment/v1/admin-internal/package',
          form,
          true
        ).then(
          (response) => {
            if (response && response.status === 201) {
              context.commit('setPackagesData', [
                ...context.state.packagesData,
                response.data.data,
              ])
            }
            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    editPackages(context, data) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        const { id, form } = data

        ApiService.put(
          `/api/space-roketin/payment/v1/admin-internal/package/${id}`,
          form
        ).then(
          (response) => {
            if (response.status === 200 && context?.state?.packagesData) {
              let packagesList = [...context.state.packagesData]
              let index = packagesList.findIndex((t) => t.id === id)

              if (index >= 0) {
                packagesList[index] = response.data.data
              }

              context.commit('setpackagesData', [...packagesList])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    deletePackages(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.delete(
          `/api/space-roketin/payment/v1/admin-internal/package/${id}`
        ).then(
          (response) => {
            if (context?.state?.packagesData) {
              const data = context.state.packagesData.filter(
                (el) => el.id !== id
              )

              context.commit('setPackagesData', [...data])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', true)
            reject(error)
          }
        )
      })
    },
  },

  mutations: {
    setPackagesData(state, data) {
      state.packagesData = data
    },
    setPackagesDetail(state, data) {
      state.packagesDetail = data
    },
    setLoading(state, data) {
      state.isLoading = data
    },
  },
}

export default packages
