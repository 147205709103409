import ApiService from '@/services/common/api.service'

const reimbursement = {
  namespaced: true,
  state: () => ({
    errors: null,
    isLoading: false,
    reimbursementRequestData: [],
    reimbursementApprovalData: [],
    reimbursementTypeData: [],
    reimbursementDetail: null,
    reimbursementApprovalDetail: null,
  }),
  getters: {
    /**
     * Get reimbursement request data
     * @param {Object} state - stored object data for database
     * @returns reimbursement request data
     */
    getReimbursementRequestData(state) {
      return state.reimbursementRequestData
    },

    /**
     * Get reimbursement approval data
     * @param {Object} state - stored object data for database
     * @returns reimbursement approval data
     */
    getReimbursementApprovalData(state) {
      return state.reimbursementApprovalData
    },

    /**
     * Get reimbursement type data
     * @param {Object} state - stored object data for database
     * @returns reimbursement type data
     */
    getReimbursementTypeData(state) {
      return state.reimbursementTypeData
    },

    /**
     * Get reimbursement detail data
     * @param {Object} state - stored object data for database
     * @returns reimbursement detail data
     */
    getReimbursementDetail(state) {
      return state.reimbursementDetail
    },

    /**
     * Get reimbursement approval data
     * @param {Object} state - stored object data for database
     * @returns reimbursement approval detail data
     */
    getReimbursementApprovalDetail(state) {
      return state.reimbursementApprovalDetail
    },
  },
  actions: {
    /**
     * Fetch reimbursement request data
     * @param {Object} context - object containing all getters and state of reimbursement request
     * @param {Object} content - request parameters object from parent component
     * @returns {Array} reimbursement request data
     */
    fetchReimbursementRequestData(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get('/api/space-roketin/reimburse/request', {
          perPage: content.perPage,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          page: content.page,
          search: content.search,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setReimbursementRequestData', [
                ...context.state.reimbursementRequestData,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Fetch reimbursement type data
     * @param {Object} context - object containing all getters and state of reimbursement request
     * @param {Object} content - request parameters object from parent component
     * @returns {Array} reimbursement request data
     */
    fetchReimbursementTypeData(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get('/api/space-roketin/reimburse-type', {
          perPage: content.perPage,
          page: content.page,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
        }).then(
          (response) => {
            if (response.status === 200) {
              content.usage === 'table'
                ? context.commit('setReimbursementTypeData', [
                    ...context.state.reimbursementTypeData,
                    ...response.data.data,
                  ])
                : context.commit('setReimbursementTypeData', [
                    ...response.data.data,
                  ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Fetch selected reimburse detail
     * @param {Object} context - object containing all getters and state of reimburse request
     * @param {integer} id - selected detail reimburse request id
     * @returns {Object} selected reimburse request detail object
     */
    fetchReimbursementDetail(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(`/api/space-roketin/reimburse/request/${id}`).then(
          (response) => {
            if (response.status === 200) {
              context.commit(
                'setReimbursementDetail',
                context.state.reimbursementDetail,
                response.data.data
              )
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Fetch selected reimburse approval detail
     * @param {Object} context - object containing all getters and state of reimburse request
     * @param {integer} id - selected detail reimburse approval id
     * @returns {Object} selected reimburse approval detail object
     */
    fetchReimbursementApprovalDetail(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(
          `/api/space-roketin/reimburse/approval/detail/${id}`
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit(
                'setReimbursementApprovalDetail',
                context.state.reimbursementApprovalDetail,
                response.data.data
              )
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Create and save new reimburse type
     * @param {Object} context - object containing all getters and state of reimburse type
     * @param {Object} form - reimburse type form data from parent component
     * @returns {Array} Reimburse type data from database
     */
    saveReimbursementTypeData(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.post('api/space-roketin/reimburse-type', form, true).then(
          (response) => {
            context.commit('setReimbursementTypeData', [
              ...context.state.reimbursementTypeData,
              response.data.data,
            ])
            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    /**
     * SaveReimbursementPic
     * @param {Object} context - object containing all getters and state of reimburse type
     * @param {Object} form - reimburse type form data from parent component
     * @returns {Object} PIC Reimbursement object
     */
    saveReimbursementPic(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.post(
          'api/space-roketin/final-approval-request',
          form,
          true
        ).then(
          (response) => {
            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    /**
     * Edit reimbursement final approval
     * @param {Object} context - object containing all getters and state of reimbursement request
     * @param {Object} form - reimbursement final approval data from parent component
     * @returns final approver name for reimbursement request
     */
    editReimbursementFinalApproval(context, form) {
      return new Promise((resolve, reject) => {
        const id = form.id

        delete form.id
        context.commit('setLoading', true)

        ApiService.put(
          `api/space-roketin/settings/reimbursement-final-approval/${id}`,
          form,
          false
        ).then(
          (response) => {
            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    /**
     * Save reimbursement final approval
     * @param {Object} context - object containing all getters and state of reimbursement request
     * @param {Object} form - reimbursement final approval data from parent component
     * @returns final approver name for reimbursement request
     */
    saveReimbursementFinalApproval(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.post(
          'api/space-roketin/settings/reimbursement-final-approval',
          form,
          true
        ).then(
          (response) => {
            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    /**
     * Edit reimburse type data
     * @param {Object} context - object containing all getters and state of reimburse type
     * @param {Object} data - reimburse type form data from parent component
     * @returns reimburse type list
     */
    editReimbursementTypeData(context, data) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.put(
          `/api/space-roketin/reimburse-type/${data.id}`,
          data,
          true
        ).then(
          (response) => {
            if (context?.state?.reimbursementTypeData) {
              const allData = context.state.reimbursementTypeData

              const filteredIndex = allData.findIndex((el) => el.id === data.id)

              const structuredData = {
                id: data.id,
                name: data.name,
                createdAt: data.createdAt,
                updatedAt: new Date(),
                companyId: data.companyId,
                companyName: data.companyName,
              }

              const result = [
                ...allData.slice(0, filteredIndex),
                structuredData,
                ...allData.slice(filteredIndex + 1),
              ]
              context.commit('setReimbursementTypeData', [...result])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    /**
     * Delete reimburse type data
     * @param {Object} context - object containing all getters and state of reimburse type
     * @param {integer} id - selected time off type id
     * @returns {Array} Time off type list
     */
    deleteReimbursementTypeData(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.delete(`/api/space-roketin/reimburse-type/${id}`).then(
          (response) => {
            if (context?.state?.reimbursementTypeData) {
              const data = context.state.reimbursementTypeData.filter(
                (el) => el.id !== id
              )
              context.commit('setReimbursementTypeData', [...data])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    /**
     * Delete reimbursement final approver
     * @param {Object} context - object containing all getters and state of reimburse data
     * @param {integer} id - final approver id
     * @returns -
     */
    deleteReimbursFinalApproval(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.delete(
          `/api/space-roketin/settings/reimbursement-final-approval/${id}`
        ).then(
          (response) => {
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    /**
     * Fetch reimburse approval list
     * @param {Object} context - object containing all getters and state of reimburse approval request
     * @param {Object} content - request parameters object from parent component
     * @returns {Array} reimburse approval list
     */
    fetchReimbursementApprovalData(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(
          `/api/space-roketin/reimburse/approval?filtering_conditions[]=${content.selectedStatus}`,
          {
            perPage: content.perPage,
            sortField: content.sortField,
            sortOrder: content.sortOrder,
            page: content.page,
            search: content.search,
            start_date: content.start_date,
            end_date: content.end_date,
            status: content.status,
          }
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setReimbursementApprovalData', [
                ...context.state.reimbursementApprovalData,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Create and save new reimburse personal request
     * @param {Object} context - object containing all getters and state of reimburse request
     * @param {Object} form - reimburse request form data from parent component
     * @returns {Array} reimburse request data from database
     */
    saveReimbursement(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.setHeaderMultipartFormData()

        ApiService.post(
          `/api/space-roketin/reimburse/request`,
          form,
          true
        ).then(
          (response) => {
            context.commit('setReimbursementRequestData', [
              ...context.state.reimbursementRequestData,
              response.data.data,
            ])

            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    /**
     * Update reimburse personal request
     * @param {Object} context - object containing all getters and state of reimburse request
     * @param {Object} data - reimburse request data containing selected id and form object
     * @returns {Array} reimburse request data from database
     */
    updateReimbursementRequest(context, data) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.setHeaderMultipartFormData()

        const { id, form } = data

        ApiService.post(
          `/api/space-roketin/reimburse/request/${id}`,
          form,
          true
        ).then(
          (response) => {
            if (
              response.status === 200 &&
              context?.state?.reimbursementRequestData
            ) {
              let ReimbursementRequestList = [
                ...context.state.reimbursementRequestData,
              ]

              let index = ReimbursementRequestList.findIndex((t) => t.id === id)

              if (index >= 0) {
                ReimbursementRequestList[index] = response.data.data
              }

              context.commit('setReimbursementRequestData', [
                ...ReimbursementRequestList,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    /**
     * Update reimburse approval request
     * @param {Object} context - object containing all getters and state of reimburse request
     * @param {Object} content - object containing id, status, and pic id
     * @returns {Array} reimburse request data from database
     */
    updateReimbursementApproval(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)

        const { id, status, reportToId } = content

        ApiService.put(`/api/space-roketin/reimburse/approval/${id}`, {
          status: status,
        }).then(
          (response) => {
            if (
              response.status === 200 &&
              context?.state?.reimbursementApprovalData
            ) {
              let reimbursementApprovalList = [
                ...context.state.reimbursementApprovalData,
              ]

              let index = reimbursementApprovalList.findIndex(
                (t) => t.id === id
              )

              if (index >= 0) {
                reimbursementApprovalList[index].approvalStatus = status

                let historyIndex = reimbursementApprovalList[
                  index
                ].history.findIndex((h) => h.reportToId === reportToId)

                if (historyIndex >= 0) {
                  // update history
                  reimbursementApprovalList[index].history[
                    historyIndex
                  ].status = status
                }
              }

              context.commit('updateApprovalStatus', [
                ...reimbursementApprovalList,
              ])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Cancel change schedule approval request
     * @param {Object} context - object containing all getters and state of change schedule request
     * @param {integer} id - selected detail change schedule request id
     * @returns {Object} selected change schedule request detail object
     */
    cancelReimbursementApproval(context, id) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)

        ApiService.put(
          `/api/space-roketin/reimburse/approval/${id}/cancel`,
          {}
        ).then(
          (response) => {
            if (
              response.status === 200 &&
              context?.state?.reimbursementApprovalData
            ) {
              context.commit('cancelAllApprovals', id)

              context.commit('setLoading', true)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', true)
            reject(error)
          }
        )
      })
    },

    /**
     * Complete reimburse approval request
     * @param {Object} context - object containing all getters and state of reimburse request
     * @param {integer} id - selected overtime request id
     * @returns {Array} overtime approval data
     */
    completeReimbursementApproval(context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(
          `/api/space-roketin/reimbursement-payment`,
          payload,
          true
        ).then(
          (response) => {
            if (response.status === 200 || response.status === 201) {
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Complete reimburse approval request
     * @param {Object} context - object containing all getters and state of reimburse request
     * @param {integer} id - selected overtime request id
     * @returns {Array} overtime approval data
     */
    completeReimbursementPayment(context, content) {
      return new Promise((resolve, reject) => {
        const { id, status, paid_date, response_note } = content

        const payload = {
          status,
        }

        if (status === 'paid') payload.paid_date = paid_date

        if (status === 'unpaid') payload.response_note = response_note

        ApiService.put(
          `/api/space-roketin/reimbursement-payment/${status}/${id}`,
          payload,
          true
        ).then(
          (response) => {
            if (response.status === 200 || response.status === 201) {
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Update Approval Status in Bulk
     * @param {Object} context - object containing all getters and state of reimburse approval request
     * @param {Object} content - object containing selected ids and status
     * @returns {Array} updated reimburse approval request status
     */
    massUpdateReimbursementApproval(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        const { status, ids } = content

        ApiService.put('/api/space-roketin/reimburse/approval/mass-update', {
          status: status,
          ids: ids,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('massUpdateApprovalStatus', {
                status,
                ids,
              })
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },
  },
  mutations: {
    /**
     * Set reimbursement request data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setReimbursementRequestData(state, data) {
      state.reimbursementRequestData = data
    },

    /**
     * Set reimbursement type data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setReimbursementTypeData(state, data) {
      state.reimbursementTypeData = data
    },

    /**
     * Set reimbursement approval data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setReimbursementApprovalData(state, data) {
      state.reimbursementApprovalData = data
    },

    /**
     * Set reimbursement detail data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setReimbursementDetail(state, data) {
      state.reimbursementDetail = data
    },

    /**
     * Set reimbursement approval detail data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setReimbursementApprovalDetail(state, data) {
      state.reimbursementApprovalDetail = data
    },

    /**
     * Set reimbursement approval status data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    updateApprovalStatus(state, data) {
      state.reimbursementApprovalData = data
    },

    /**
     * Set multiple reimbursement approval status data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    massUpdateApprovalStatus(state, data) {
      const { status, ids } = data
      let reimbursementApprovalList = [...state.reimbursementApprovalData]

      reimbursementApprovalList.forEach((t) => {
        if (ids.includes(t.id)) {
          t.approvalStatus = status
        }
      })
      state.reimbursementApprovalData = reimbursementApprovalList
    },

    /**
     * Cancel reimbursement approval data
     * @param {Object} state - stored object data for database
     * @param {integer} id - request id
     */
    cancelAllApprovals(state, id) {
      let reimbursementApprovalData = [...state.reimbursementApprovalData]
      let index = reimbursementApprovalData.findIndex((t) => t.id === id)
      if (index >= 0) {
        reimbursementApprovalData[index].approvalStatus = 'canceled'

        // update all history's status to canceled
        for (let key in reimbursementApprovalData[index].history) {
          reimbursementApprovalData[index].history[key].status = 'canceled'
        }
        state.reimbursementApprovalData = reimbursementApprovalData
      }
    },

    /**
     * Complete reimbursement approval data
     * @param {Object} state - stored object data for database
     * @param {integer} id - request id
     */
    completeApprovals(state, id) {
      let reimbursementApprovalData = [...state.reimbursementApprovalData]
      let index = reimbursementApprovalData.findIndex((t) => t.id === id)
      if (index >= 0) {
        state.reimbursementApprovalData = reimbursementApprovalData
      }
    },

    /**
     * Set loading state
     * @param {Object} state - stored object data for database
     * @param {Object} data - loading state
     */
    setLoading(state, data) {
      state.isLoading = data
    },
  },
}

export default reimbursement
