import ApiService from '@/services/common/api.service'

const releaseNoteModule = {
  namespaced: true,
  state: () => ({
    errors: null,
    releaseNoteList: [],
    isLoading: false,
  }),
  getters: {
    getReleaseNotesList(state) {
      return state.releaseNoteList
    },
  },

  actions: {
    fetchReleaseNotesList(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(`/api/space-roketin/release-note`, {
          perPage: content.perPage,
          page: content.page,
          orderBy: content.sortField,
          orderDirection: content.sortOrder,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setReleaseNoteList', [
                ...context.state.releaseNoteList,
                ...response.data.data,
              ])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    saveReleaseNote(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.post('/api/space-roketin/release-note', form, true).then(
          (response) => {
            context.commit('setReleaseNoteList', [
              ...context.state.releaseNoteList,
              response.data.data,
            ])
            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    editReleaseNote(context, data) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        const { id, form } = data

        ApiService.post(
          `/api/space-roketin/release-note/${id}`,
          form,
          true
        ).then(
          (response) => {
            if (response.status === 200 && context?.state?.releaseNoteList) {
              let ReleaseNoteList = [...context.state.releaseNoteList]
              const filteredIndex = ReleaseNoteList.findIndex(
                (el) => el.id === id
              )

              if (filteredIndex >= 0) {
                ReleaseNoteList[filteredIndex] = response.data.data
              }

              context.commit('setReleaseNoteList', [...ReleaseNoteList])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    deleteReleaseNote(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        console.log(id)

        ApiService.delete(`/api/space-roketin/release-note/${id}`).then(
          (response) => {
            if (context?.state?.releaseNoteList) {
              const data = context.state.releaseNoteList.filter(
                (el) => el.id !== id
              )
              context.commit('setReleaseNoteList', [...data])
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },
  },
  mutations: {
    setLoading(state, data) {
      state.isLoading = data
    },
    setReleaseNoteList(state, data) {
      state.releaseNoteList = data
    },
  },
}

export default releaseNoteModule
