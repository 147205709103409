import ApiService from '@/services/common/api.service'

const timeOffModule = {
  namespaced: true,
  state: () => ({
    errors: null,
    timeoffs: [],
    timeOffApprovalList: [],
    isLoading: false,
    timeOffTypes: [],
    allTimeOffTypes: [],
    timeOffDetail: null,
    timeOffApprovalDetail: null,
  }),
  getters: {
    /**
     * Get time off request data
     * @param {Object} state - stored object data for database
     * @returns overtime request data
     */
    getTimeoffs(state) {
      return state.timeoffs
    },

    /**
     * Get time off type list
     * @param {Array} state - stored object data for database
     * @returns time off type list
     */
    getTimeOffTypes(state) {
      return state.timeOffTypes
    },

    /**
     * Get time off approval request data
     * @param {Object} state - stored object data for database
     * @returns overtime approval data
     */
    getTimeoffApprovalList(state) {
      return state.timeOffApprovalList
    },

    /**
     * Get all time off types list
     * @param {Object} state - stored object data for database
     * @returns {Array} all timeoff types array list
     */
    getAllTimeOffTypesList(state) {
      return state.allTimeOffTypes
    },

    /**
     * Get all time off detail data
     * @param {Object} state - stored object data for database
     * @returns {Array} all timeoff detail array list
     */
    getAllTimeOffDetail(state) {
      return state.timeOffDetail
    },

    /**
     * Get time off approval detail data
     * @param {Object} state - stored object data for database
     * @returns timeoff approval data
     */
    getTimeOffApprovalDetail(state) {
      return state.timeoffApprovalDetail
    },
  },
  actions: {
    /**
     * Load time off type data
     * @param {Object} context - object containing all getters and state of time off request
     * @returns {Array} time off types list
     */
    getTimeOffTypes(context) {
      return new Promise((resolve, reject) => {
        ApiService.get('/api/space-roketin/time-off/get-filter-time-off').then(
          (response) => {
            if (response.status === 200) {
              context.commit('setTimeOffTypes', response.data)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    /**
     * Update time off approval request status
     * @param {Object} context - object containing all getters and state of time off request
     * @param {Object} content - object containing id, status, pic id, and reason
     * @returns {Array} time off approval data from database
     */
    updateStatusApproval(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        const { id, status, reportToId, reason } = content
        ApiService.put(`/api/space-roketin/time-off/v3/approval/${id}`, {
          status,
          reason,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('updateStatusApproval', {
                id,
                status,
                reportToId,
                reason,
              })
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    /**
     * Cancel time off approval data
     * @param {Object} context - object containing all getters and state of time off request
     * @param {integer} id - selected detail time off request id
     * @returns {Object} selected time off approval data
     */
    cancelApproval(context, id) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)

        ApiService.put(
          `/api/space-roketin/time-off/v3/approval/${id}/cancel`,
          {}
        ).then(
          (response) => {
            if (
              response.status === 200 &&
              context?.state?.timeOffApprovalList
            ) {
              context.commit('cancelAllApprovals', id)

              context.commit('setLoading', true)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', true)
            reject(error)
          }
        )
      })
    },

    /**
     * Fetch time off approval list
     * @param {Object} context - object containing all getters and state of time off approval request
     * @param {Object} content - request parameters object from parent component
     * @returns {Array} time off approval list
     */
    fetchListTimeOffApproval(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)
        ApiService.get(
          `/api/space-roketin/time-off/v3/approval?filtering_conditions[]=${content.selectedStatus}`,
          {
            perPage: content.perPage,
            page: content.page,
            sortField: content.sortField,
            sortOrder: content.sortOrder,
            search: content.search,
            start_date: content.start_date,
            end_date: content.end_date,
            status: content.status,
          }
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setTimeoffsApprovalList', [
                ...context.state.timeOffApprovalList,
                ...response.data.data,
              ])
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    /**
     * Fetch time off request list
     * @param {Object} context - object containing all getters and state of time off request
     * @param {Object} content - request parameters object from parent component
     * @returns {Array} time off request list
     */
    fetchMyRequestTimeoffs(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)
        ApiService.get(`/api/space-roketin/time-off/v3/request`, {
          perPage: content.perPage,
          page: content.page,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          search: content.search,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setTimeoffs', [
                ...context.state.timeoffs,
                ...response.data.data,
              ])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            // context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Fetch selected time off detail
     * @param {Object} context - object containing all getters and state of time off request
     * @param {integer} id - selected detail time off request id
     * @returns {Object} selected time off request detail object
     */
    fetchTimeOffDetail(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(`/api/space-roketin/time-off/v3/request/${id}`).then(
          (response) => {
            if (response.status === 200) {
              context.commit(
                'setTimeOffDetail',
                context.state.timeOffDetail,
                response.data.data
              )
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Fetch selected approval time off detail
     * @param {Object} context - object containing all getters and state of time off request
     * @param {integer} id - selected detail time off approval id
     * @returns {Object} selected time off approval detail object
     */
    fetchTimeOffApprovalDetail(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(
          `/api/space-roketin/time-off/v3/approval/detail/${id}`
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit(
                'setTimeOffApprovalDetail',
                context.state.timeOffApprovalDetail,
                response.data.data
              )
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Create and save new time off request
     * @param {Object} context - object containing all getters and state of time off request
     * @param {Object} form - time off request form data from parent component
     * @returns {Array} Time off request data from database
     */
    saveTimeoff(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.setHeaderMultipartFormData()
        ApiService.post(
          `/api/space-roketin/time-off/v3/request`,
          form,
          true
        ).then(
          (response) => {
            context.commit('setTimeoffs', [
              response.data.data,
              ...context.state.timeoffs,
            ])
            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    /**
     * Update time off request
     * @param {Object} context - object containing all getters and state of time off request
     * @param {Object} data - time off request data containing selected id and form object
     * @returns {Array} Time off request data from database
     */
    updateTimeoff(context, data) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        const { form, id } = data
        ApiService.post(
          `/api/space-roketin/time-off/v3/request/${id}`,
          form,
          true
        ).then(
          (response) => {
            context.commit('updateSpecificRequestTimeOff', response.data.data)
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    /**
     * Update Approval Status in Bulk
     * @param {Object} context - object containing all getters and state of time off request
     * @param {Object} content - object containing selected ids and status
     * @returns {Array} updated time off approval request status
     */
    massUpdateTimeOffApproval(context, content) {
      return new Promise((resolve, reject) => {
        const { ids, status } = content
        ApiService.put('/api/space-roketin/time-off/v3/approval/mass-update', {
          ids,
          status,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('massUpdateStatusTimeOffApproval', {
                ids,
                status,
              })
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    /**
     * Cancel time off request
     * @param {Object} context - object containing all getters and state of time off request
     * @param {Object} content - request parameters object from parent component
     * @returns {Object} selected overtime request detail object
     */
    cancelTimeoff(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.post(
          '/api/space-roketin/time-off/v3/request/' +
            content.id +
            '/cancel-time-off',
          {
            reason: content.reason,
          }
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setLoading', false)
              context.commit('cancelSpecificRequestTimeOff', response.data)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    /**
     * Get my and team's time off data for dashboard calendar
     * @param {null} _ - null data
     * @param {Date} date - month and year of dashboard calendar
     * @returns {Array} Time off data for dashboard calendar
     */
    getAllTimeOffs(_, date) {
      return new Promise((resolve, reject) => {
        ApiService.get(
          `/api/space-roketin/calendar-dashboard?month=${date[0]}&year=${date[1]}`
        ).then(
          (response) => {
            if (response.status === 200) {
              resolve(response.data.data)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    /**
     * Get all time off type list
     * @param {Object} context - object containing all getters and state of time off type
     * @param {Object} content - request parameters object from parent component
     * @returns {Array} time off type list data
     */
    getAllTimeOffTypes(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(`/api/space-roketin/time-off-type`, {
          perPage: content.perPage,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          page: content.page,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setAllTimeOffTypes', [
                ...context.state.allTimeOffTypes,
                ...response.data.data,
              ])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // To create timeoff type
    /**
     * Save Time off type list
     * @param {Object} context - object containing all getters and state of time off type
     * @param {Object} form - time off type form data from parent component
     * @returns time off type list
     */
    saveTimeoffType(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.setHeaderMultipartFormData()

        ApiService.post(
          `/api/space-roketin/time-off/time-off-type/store`,
          form,
          true
        ).then(
          (response) => {
            context.commit('setAllTimeOffTypes', [
              ...context.state.allTimeOffTypes,
              response.data.data,
            ])
            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    /**
     * Edit time off type
     * @param {Object} context - object containing all getters and state of time off type
     * @param {Object} data - time off type form data from parent component
     * @returns time off type list
     */
    editTimeoffType(context, data) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.setHeaderMultipartFormData()

        ApiService.put(
          `/api/space-roketin/time-off/time-off-type/${data.id}/update`,
          data,
          true
        ).then(
          (response) => {
            if (context?.state?.allTimeOffTypes) {
              const allData = context.state.allTimeOffTypes

              const filteredIndex = allData.findIndex((el) => el.id === data.id)

              const structuredData = {
                id: data.id,
                name: data.name,
                paidDays: data.paid_days,
                timeType: data.time_type,
                createdAt: data.createdAt,
                updatedAt: new Date(),
              }

              const result = [
                ...allData.slice(0, filteredIndex),
                structuredData,
                ...allData.slice(filteredIndex + 1),
              ]
              context.commit('setAllTimeOffTypes', [...result])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    /**
     * Delete time off type data
     * @param {Object} context - object containing all getters and state of time off type
     * @param {integer} id - selected time off type id
     * @returns {Array} Time off type list
     */
    deleteTimeoffType(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.delete(
          `/api/space-roketin/time-off/time-off-type/${id}/delete`
        ).then(
          (response) => {
            if (context?.state?.allTimeOffTypes) {
              const data = context.state.allTimeOffTypes.filter(
                (el) => el.id !== id
              )
              context.commit('setAllTimeOffTypes', [...data])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
  },
  mutations: {
    /**
     * Set time off type data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setTimeOffTypes(state, data) {
      state.timeOffTypes = data
    },

    /**
     * Set loading state
     * @param {Object} state - stored object data for database
     * @param {Object} data - loading state
     */
    setLoading(state, data) {
      state.isLoading = data
    },

    /**
     * Set time off detail data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setTimeOffDetail(state, data) {
      state.timeOffDetail = data
    },

    /**
     * Set time off approval detail data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setTimeOffApprovalDetail(state, data) {
      state.timeOffApprovalDetail = data
    },

    /**
     * Set time off request data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setTimeoffs(state, data) {
      state.timeoffs = data
    },

    /**
     * Set time off approval list data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setTimeoffsApprovalList(state, data) {
      state.timeOffApprovalList = data
    },

    /**
     * Cancel selected time of request
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    cancelSpecificRequestTimeOff(state, data) {
      let timeOffRequestList = [...state.timeoffs]
      let index = timeOffRequestList.findIndex((t) => t.id === data.id)
      if (index >= 0) {
        timeOffRequestList[index].requestStatus = 'canceled'
        for (let key in timeOffRequestList[index].history) {
          timeOffRequestList[index].history[key].status = 'canceled'
          timeOffRequestList[index].history[key].approvalReason =
            'canceled by requested user'
        }
        state.timeoffs = timeOffRequestList
      }
    },

    /**
     * Remove file from time off request
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    removeSpecificFileFromRequestTimeoff(state, data) {
      let { timeOffId, fileId } = data
      let timeOffRequestList = [...state.timeoffs]
      let index = timeOffRequestList.findIndex((t) => t.id === timeOffId)
      if (index >= 0) {
        timeOffRequestList[index].files = timeOffRequestList[
          index
        ].files.filter((f) => f.id !== fileId)
        state.timeoffs = timeOffRequestList
      }
    },
    /**
     * Update request time off
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    updateSpecificRequestTimeOff(state, data) {
      let timeOffRequestList = [...state.timeoffs]
      let index = timeOffRequestList.findIndex((t) => t.id === data.id)
      if (index >= 0) {
        timeOffRequestList[index] = data
        state.timeoffs = timeOffRequestList
      }
    },

    /**
     * Update approval status
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    updateStatusApproval(state, data) {
      const { id, status, reportToId, reason } = data
      let timeOffApprovalList = [...state.timeOffApprovalList]
      let index = timeOffApprovalList.findIndex((t) => t.id === id)
      if (index >= 0) {
        timeOffApprovalList[index].approvalStatus = status
        timeOffApprovalList[index].approvalReason = reason
        timeOffApprovalList[index].approvable = false

        let historyIndex = timeOffApprovalList[index].history.findIndex(
          (h) => h.reportToId === reportToId
        )
        if (historyIndex >= 0) {
          // update history
          timeOffApprovalList[index].history[historyIndex].status = status
          timeOffApprovalList[index].history[
            historyIndex
          ].approvalReason = reason
        }
        state.timeOffApprovalList = timeOffApprovalList
      }
    },

    /**
     * Cancel bulk approval request
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    cancelAllApprovals(state, id) {
      let timeOffApprovalList = [...state.timeOffApprovalList]
      let index = timeOffApprovalList.findIndex((t) => t.id === id)
      if (index >= 0) {
        timeOffApprovalList[index].approvalStatus = 'canceled'
        timeOffApprovalList[index].approvable = false

        // update all history's status to canceled
        for (let key in timeOffApprovalList[index].history) {
          timeOffApprovalList[index].history[key].status = 'canceled'
        }
        state.timeOffApprovalList = timeOffApprovalList
      }
    },

    /**
     * Mass update time off approval request
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    massUpdateStatusTimeOffApproval(state, data) {
      const { ids, status } = data
      let timeOffApprovalList = [...state.timeOffApprovalList]
      timeOffApprovalList.forEach((t) => {
        if (ids.includes(t.id)) {
          t.approvalStatus = status
          t.approvable = false
        }
      })
      state.timeOffApprovalList = timeOffApprovalList
    },

    /**
     * Set all time off type list
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setAllTimeOffTypes(state, data) {
      state.allTimeOffTypes = data
    },
  },
}

export default timeOffModule
