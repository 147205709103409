import ApiService from '@/services/common/api.service'

const settings = {
  namespaced: true,
  state: () => ({
    errors: null,
    globalTimeoffs: [],
  }),
  getters: {
    /**
     * For Global Timeoffs
     */
    getGlobalTimeoffByIndex: (state) => (index) => state.globalTimeoffs[index],
    getGlobalTimeoffs(state) {
      return state.globalTimeoffs
    },
  },
  actions: {
    /**
     * For Global Timeoffs
     */
    fetchGlobalTimeoffs(context) {
      return new Promise((resolve, reject) => {
        ApiService.get('/api/space-roketin/global-time-off').then(
          (response) => {
            if (response.status === 200) {
              context.commit('setGlobalTimeoffs', response.data)

              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
  },
  mutations: {
    /**
     * For Global Timeoffs
     */
    setGlobalTimeoffs(state, data) {
      state.globalTimeoffs = data
    },
    pushGlobalTimeoff(state, data) {
      let globalTimeoffs = [...state.globalTimeoffs]
      globalTimeoffs.unshift(data)
      state.globalTimeoffs = globalTimeoffs
    },
    updateGlobalTimeoff(state, data) {
      let getIndex = (element) => element.id === data.id
      let updatedIndex = state.globalTimeoffs.findIndex(getIndex)
      let newList = [...state.globalTimeoffs]
      newList[updatedIndex] = data
      state.globalTimeoffs = newList
    },
    deleteGlobalTimeoff(state, id) {
      state.globalTimeoffs = state.globalTimeoffs.filter(
        (element) => element.id !== id
      )
    },
  },
}

export default settings
