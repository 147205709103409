import ApiService from '@/services/common/api.service'

const companies = {
  namespaced: true,
  state: () => ({
    errors: null,
    isLoading: false,
    userManagementData: [],
  }),
  getters: {
    getCompaniesUserManagementData(state) {
      return state.userManagementData
    },
  },
  actions: {
    fetchCompaniesUserManagementData(context, content) {
      const { id, parameter } = content
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.get(
          `/api/space-roketin/admin-internal/company/user-management/${id}?${parameter}`
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setUserManagementData', [
                ...context.state.userManagementData,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },
  },

  mutations: {
    setUserManagementData(state, data) {
      state.userManagementData = data
    },
    setLoading(state, data) {
      state.isLoading = data
    },
  },
}

export default companies
