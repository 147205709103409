import ApiService from '@/services/common/api.service'

const employmentModule = {
  namespaced: true,
  state: () => ({
    errors: null,
    employeeGeneralList: [],
    employeeContractList: [],
    employeeArchiveList: [],
    employmentMemberList: [],
    isLoading: false,
    isHasMember: false,
    isNotHaveMember: false,
  }),
  getters: {
    // To get employee list general
    getEmployeeGeneralList(state) {
      return state.employeeGeneralList
    },

    // To get employee list contract
    getEmployeeContractList(state) {
      return state.employeeContractList
    },

    // To get employee list archive
    getEmployeeArchiveList(state) {
      return state.employeeArchiveList
    },

    // to get employee member team
    getEmployeeMember(state) {
      return state.employmentMemberList
    },

    getEmployeeHasMember(state) {
      return state.isHasMember
    },

    getNotHaveMember(state) {
      return state.isNotHaveMember
    },
  },
  actions: {
    // To get employee list general
    fetchEmployeeListGeneral(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)

        ApiService.get(`/api/space-roketin/employee-directory/general`, {
          search: content.search,
          perPage: content.perPage,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          page: content.page,
          isActive: content.isActive,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setEmployeeListGeneral', [
                ...context.state.employeeGeneralList,
                ...response.data.data,
              ])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // To get employee list contract
    fetchEmployeeListContract(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)

        ApiService.get(`/api/space-roketin/employee-directory/contract`, {
          search: content.search,
          perPage: content.perPage,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          page: content.page,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setEmployeeListContract', [
                ...context.state.employeeContractList,
                ...response.data.data,
              ])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    fetchEmployeeListArchive(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)

        ApiService.get('/api/space-roketin/employee/archive/archived', {
          search: content.search,
          perPage: content.perPage,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          page: content.page,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setEmployeeListArchive', [
                ...context.state.employeeArchiveList,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    archiveEmployee(context, id, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.post(
          `/api/space-roketin/employee/archive/${id}`,
          form,
          true
        ).then(
          (response) => {
            if (context?.state?.employeeGeneralList) {
              const data = context.state.employeeGeneralList.filter(
                (el) => el.id !== id
              )
              context.commit('setEmployeeListGeneral', [...data])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setemploymentMemberList', [
              ...error?.response.data.meta.reportingTo,
            ])
            context.commit('setIsHasMember', true)
            reject(error)
            // console.log(error?.response.data.meta.reportingTo);
          }
        )
      })
    },

    restoreEmployee(context, id, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.post(
          `/api/space-roketin/employee/archive/${id}/restore`,
          form,
          true
        ).then(
          (response) => {
            if (context?.state?.employeeArchiveList) {
              const data = context.state.employeeArchiveList.filter(
                (el) => el.id !== id
              )
              context.commit('setEmployeeListArchive', [...data])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    updateEmployeeMember(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.put(
          `/api/space-roketin/employment/reporting-to/mass-update`,
          { employments: form },
          true
        ).then(
          (response) => {
            context.commit('setisNotHaveMember', true)
            resolve(response)
            if (response.status == 200) {
              context.commit('setIsHasMember', false)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    // close modal from store for condition if employment has reporting to
    onCloseModal(context) {
      context.commit('setIsHasMember', false)
    },
  },
  mutations: {
    // For set loading
    setLoading(state, data) {
      state.isLoading = data
    },

    setEmployeeListGeneral(state, data) {
      state.employeeGeneralList = data
    },

    setemploymentMemberList(state, data) {
      state.employmentMemberList = data
    },

    setIsHasMember(state, data) {
      state.isHasMember = data
    },

    setEmployeeListContract(state, data) {
      state.employeeContractList = data
    },

    setEmployeeListArchive(state, data) {
      state.employeeArchiveList = data
    },

    setisNotHaveMember(state, data) {
      state.isNotHaveMember = data
    },
  },
}

export default employmentModule
