import ApiService from '@/services/common/api.service'
import { showToast } from '@/services/util'

const activityCategory = {
  namespaced: true,
  state: () => ({
    // Activity Category Table
    data: [],
    isLoading: false,
    sortBy: 'name',
    sortOrder: 'desc',
    defaultSortOrder: 'desc',
    page: 1,
    perPage: 5,
    totalData: 0,

    // Create Edit Modal
    formData: {
      id: null,
      name: null,
      description: null,
    },
    isCreateEditModalOpen: false,
    isEditing: false,
    isSubmitting: false,
  }),
  actions: {
    /**
     * Get Activity Category data
     * @param {Object} context - An object which exposes the same set of methods/properties on the store instance
     */
    async getActivityCategory(context) {
      context.commit('setLoading', true)
      try {
        let response = await ApiService.get(
          '/api/space-roketin/activity/type/collection',
          {
            sortBy: context.state.sortBy,
            sortOrder: context.state.sortOrder,
            page: context.state.page,
            perPage: context.state.perPage,
          }
        )
        context.commit('setData', response.data.data)
        context.commit('setTotalData', response.data.total)
      } catch (error) {
        showToast(
          'Failed to fetch data. Please try again!',
          'is-danger',
          'is-top'
        )
      }
      context.commit('setLoading', false)
    },
    /**
     * Create or Edit Activity Category based on isEditing state,
     * the data sent will be taken from the formData state
     * @param {Object} context - An object which exposes the same set of methods/properties on the store instance
     */
    async createEditActivityCategory(context) {
      context.commit('setSubmitting', true)
      try {
        if (context.state.isEditing) {
          await ApiService.put('/api/space-roketin/activity/type', {
            id: context.state.formData.id,
            name: context.state.formData.name,
            description: context.state.formData.description || '',
          })
          showToast('Data is successfully edited!', 'is-success', 'is-top')
        } else {
          await ApiService.post('/api/space-roketin/activity/type', {
            name: context.state.formData.name,
            description: context.state.formData.description || '',
          })
          showToast('Data is successfully added!', 'is-success', 'is-top')
        }
        context.dispatch('closeCreateEditModal')
        context.dispatch('getActivityCategory')
      } catch (error) {
        if (context.state.isEditing) {
          showToast(
            'Failed to edit data. Please try again!',
            'is-danger',
            'is-top'
          )
        } else {
          showToast(
            'Failed to add data. Please try again!',
            'is-danger',
            'is-top'
          )
        }
      }
      context.commit('setSubmitting', false)
    },
    /**
     * Delete Activity Category based on selected row id
     * @param {Object} context - An object which exposes the same set of methods/properties on the store instance
     * @param {Number} id - Selected Activity Category's id to be deleted
     */
    async deleteActivityCategory(context, id) {
      context.commit('setLoading', true)
      try {
        await ApiService.delete(`/api/space-roketin/activity/type/${id}`)
        showToast('Data is successfully deleted!', 'is-success', 'is-top')
        context.dispatch('getActivityCategory')
      } catch (error) {
        showToast(
          'Failed to delete data. Please try again!',
          'is-danger',
          'is-top'
        )
      }
      context.commit('setLoading', false)
    },
    /**
     * Reset Create Edit Modal's form data
     * @param {Object} context - An object which exposes the same set of methods/properties on the store instance
     */
    resetCreateEditForm(context) {
      context.commit('setFormData', {
        id: null,
        name: null,
        description: null,
      })
    },
    /**
     * Open Create or Edit Modal with a condition:
     * if row is not empty then open Edit Modal, otherwise open Create Modal
     * @param {Object} context - An object which exposes the same set of methods/properties on the store instance
     * @param {Object} row - A row containing existing Activity Category data
     */
    openCreateEditModal(context, row) {
      if (row) {
        context.commit('setEditing', true)
        context.commit('setFormData', {
          id: row.id,
          name: row.name,
          description: row.description,
        })
      }
      context.commit('setCreateEditModalOpen', true)
    },
    /**
     * Close Create or Edit Modal
     * @param {Object} context - An object which exposes the same set of methods/properties on the store instance
     */
    closeCreateEditModal(context) {
      context.dispatch('resetCreateEditForm')
      context.commit('setCreateEditModalOpen', false)
      context.commit('setEditing', false)
    },
    /**
     * Open Delete Modal
     * @param {Object} context - An object which exposes the same set of methods/properties on the store instance
     * @param {Number} id - Selected Activity Category's id to be deleted
     */
    openDeleteModal(context, id) {
      this._vm
        .$swal({
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Continue',
          customClass: {
            confirmButton: 'button is-primary',
            denyButton: 'button is-primary',
            cancelButton: 'button',
          },
          text: 'Are you sure you want to delete this data?',
        })
        .then((result) => {
          if (result.isConfirmed) {
            context.dispatch('deleteActivityCategory', id)
          }
        })
    },
  },
  mutations: {
    setData(state, newData) {
      state.data = newData
    },
    setLoading(state, newState) {
      state.isLoading = newState
    },
    setFormData(state, newFormData) {
      state.formData = newFormData
    },
    setEditing(state, newState) {
      state.isEditing = newState
    },
    setCreateEditModalOpen(state, newState) {
      state.isCreateEditModalOpen = newState
    },
    setSubmitting(state, newState) {
      state.isSubmitting = newState
    },
    setPage(state, newPage) {
      state.page = newPage
    },
    setTotalData(state, newTotalData) {
      state.totalData = newTotalData
    },
    setSort(state, { field, order }) {
      state.sortBy = field
      state.sortOrder = order
    },
  },
}

export default activityCategory
