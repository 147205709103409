import ApiService from '@/services/common/api.service'

const logActivity = {
  namespaced: true,
  state: () => ({
    errors: null,
    isLoading: false,
    logActivityPersonal: [],
    logActivityTeam: [],
    logActivityDetail: null,
    logActivityApprovalDetail: null,
  }),
  getters: {
    /**
     * Get log activity personal request data
     * @param {Object} state - stored object data for database
     * @returns log activity request data
     */
    getLogActivityPersonal(state) {
      return state.logActivityPersonal
    },

    /**
     * Get log activity team request data
     * @param {Object} state - stored object data for database
     * @returns log activity team request data
     */
    getLogActivityTeam(state) {
      return state.logActivityTeam
    },

    /**
     * Get log activity personal detail data
     * @param {Object} state - stored object data for database
     * @returns log activity detail data
     */
    getLogActivityDetail(state) {
      return state.logActivityDetail
    },

    /**
     * Get log activity approval detail data
     * @param {Object} state - stored object data for database
     * @returns log activity approval detail data
     */
    getLogActivityApprovalDetail(state) {
      return state.logActivityApprovalDetail
    },
  },
  actions: {
    /**
     * Fetch attendance adjustment personal data
     * @param {Object} context - object containing all getters and state of attendance adjustment request
     * @param {Object} content - request parameters object from parent component
     * @returns {Array} attendance adjustment request data
     */
    fetchLogActivityPersonal(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get('/api/space-roketin/v3/request/adjustment', {
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          perPage: content.perPage,
          page: content.page,
          search: content.search,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setLogActivityPersonal', [
                ...context.state.logActivityPersonal,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Fetch attendance adjustment management data
     * @param {Object} context - object containing all getters and state of attendance adjustment approval
     * @param {Object} content - request parameters object from parent component
     * @returns {Array} attendance adjustment approval data
     */
    fetchLogActivityTeam(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(
          `/api/space-roketin/v3/request/adjustment/approval?filtering_conditions[]=${content.selectedStatus}`,
          {
            perPage: content.perPage,
            sortField: content.sortField,
            sortOrder: content.sortOrder,
            page: content.page,
            search: content.search,
            start_date: content.start_date,
            end_date: content.end_date,
            status: content.status,
          }
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setLogActivityTeam', [
                ...context.state.logActivityTeam,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Fetch selected attendance adjustment detail
     * @param {Object} context - object containing all getters and state of attendance adjustment request
     * @param {integer} id - selected detail attendance adjustment request id
     * @returns {Object} selected attendance adjustment request detail object
     */
    fetchLogActivityDetail(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(`/api/space-roketin/v3/request/adjustment/${id}`).then(
          (response) => {
            if (response.status === 200) {
              context.commit(
                'setLogActivityDetail',
                context.state.logActivityDetail,
                response.data.data
              )
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Fetch selected attendance adjustment approval detail
     * @param {Object} context - object containing all getters and state of attendance adjustment approval
     * @param {integer} id - selected detail attendance adjustment approval id
     * @returns {Object} selected attendance adjustment approval detail object
     */
    fetchLogActivityApprovalDetail(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(
          `/api/space-roketin/v3/request/adjustment/approval/detail/${id}`
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit(
                'setLogActivityApprovalDetail',
                context.state.logActivityApprovalDetail,
                response.data.data
              )
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Update attendance adjustment approval request
     * @param {Object} context - object containing all getters and state of attendance adjustment approval
     * @param {Object} content - object containing selected id, status, and pic id
     * @returns {Array} Attendance adjustment approval data from database
     */
    updateLogActivityApprovalRequests(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)

        const { id, status, reportToId } = content

        ApiService.put(
          `/api/space-roketin/v3/request/adjustment/approval/${id}`,
          {
            status: status,
          }
        ).then(
          (response) => {
            if (response.status === 200 && context?.state?.logActivityTeam) {
              let logActivityTeamList = [...context.state.logActivityTeam]

              let index = logActivityTeamList.findIndex((t) => t.id === id)

              if (index >= 0) {
                logActivityTeamList[index].status = status

                let historyIndex = logActivityTeamList[index].history.findIndex(
                  (h) => h.reportToId === reportToId
                )

                if (historyIndex >= 0) {
                  // update history
                  logActivityTeamList[index].history[
                    historyIndex
                  ].status = status
                }
              }

              context.commit('updateApprovalStatus', [...logActivityTeamList])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    /**
     * Cancel attendance adjustment approval request
     * @param {Object} context - object containing all getters and state of attendance adjustment approval
     * @param {integer} id - selected detail attendance adjustment approval id
     * @returns {Object} selected attendance adjustment approval detail object
     */
    cancelLogActivityApproval(context, id) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)

        ApiService.put(
          `/api/space-roketin/v3/request/adjustment/approval/${id}/cancel`,
          {}
        ).then(
          (response) => {
            if (response.status === 200 && context?.state?.logActivityTeam) {
              context.commit('cancelAllApprovals', id)

              context.commit('setLoading', true)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', true)
            reject(error)
          }
        )
      })
    },

    /**
     * Update Approval Status in Bulk
     * @param {Object} context - object containing all getters and state of attendance adjustment request
     * @param {Object} content - object containing selected ids and status
     * @returns {Array} updated attendance adjustment approval request status
     */
    massUpdateLogActivityApprovalRequests(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        const { status, approvalIds } = content

        ApiService.put(
          '/api/space-roketin/v3/request/adjustment/approval/bulk',
          {
            status: status,
            approval_ids: approvalIds,
          }
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit('massUpdateApprovalStatus', {
                status,
                approvalIds,
              })
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },
  },
  mutations: {
    /**
     * Set attendance adjustment request data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setLogActivityPersonal(state, data) {
      state.logActivityPersonal = data
    },

    /**
     * Set attendance adjustment approval data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setLogActivityTeam(state, data) {
      state.logActivityTeam = data
    },

    /**
     * Set attendance adjustment detail data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setLogActivityDetail(state, data) {
      state.logActivityDetail = data
    },

    /**
     * Set attendance adjustment approval detail data
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    setLogActivityApprovalDetail(state, data) {
      state.logActivityApprovalDetail = data
    },

    /**
     * Update attendance adjustment approval status
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    updateApprovalStatus(state, data) {
      state.logActivityTeam = data
    },

    /**
     * Set multiple attendance adjustment request status
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    massUpdateApprovalStatus(state, data) {
      const { status, approvalIds } = data
      let logActivityTeamList = [...state.logActivityTeam]

      logActivityTeamList.forEach((t) => {
        if (approvalIds.includes(t.id)) {
          t.status = status
        }
      })
      state.logActivityTeam = logActivityTeamList
    },

    /**
     * Cancel attendance adjustment approval request
     * @param {Object} state - stored object data for database
     * @param {Object} data - response data
     */
    cancelAllApprovals(state, id) {
      let logActivityTeam = [...state.logActivityTeam]
      let index = logActivityTeam.findIndex((t) => t.id === id)
      if (index >= 0) {
        logActivityTeam[index].status = 'canceled'

        // update all history's status to canceled
        for (let key in logActivityTeam[index].history) {
          logActivityTeam[index].history[key].status = 'canceled'
        }
        state.logActivityTeam = logActivityTeam
      }
    },

    /**
     * Set loading state
     * @param {Object} state - stored object data for database
     * @param {Object} data - loading state
     */
    setLoading(state, data) {
      state.isLoading = data
    },
  },
}

export default logActivity
